import {
    Box,
} from '@mui/material';
import { myLoader } from '@/libs/fncs';
import Image from 'next/legacy/image';
import { isApp } from '@/libs/store';

export default function TopBigAd({ adtype }) {

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ display: { xs: "block", md: "none" }, width: "320px", height: "50px", position: "relative", borderRadius: 1, overflow: "hidden" }}>
                {
                    adtype.get() == "own" ?
                        <Image loader={myLoader} unoptimized={true} src="/320x50.png" alt="ad" layout="fill" objectFit={'contain'} onClick={() => window.open("http://pennytoken.org", "_blank")} />
                        :
                        adtype.get() == "bm" && !isApp ?
                            <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <ins className="62f4e0ae6703276b08cd86e4" style={{ display: "inline-block", width: '320px', height: '50px' }}></ins>
                            </Box>
                            :
                            <iframe title="320x50 Ad" data-aa='2189429' src='//ad.a-ads.com/2189429?size=320x50' style={{ width: '320px', height: '50px', border: '0px', padding: 0, overflow: 'hidden', backgroundColor: 'transparent' }}></iframe>
                }
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" }, height: "90px", width: "728px", position: "relative", borderRadius: 1, overflow: "hidden" }}>
                {
                    adtype.get() == "own" ?
                        <video width="100%" height="100%" muted autoPlay loop >
                            <source src="/728x90.mp4" type="video/mp4" />
                        </video>
                        :
                        adtype.get() == "bm" && !isApp ?
                            <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <ins className="62f4dba0e09b862c31169b6a" style={{ display: "inline-block", width: '728px', height: '90px' }}></ins>
                            </Box>
                            :
                            <iframe title="728x90 Ad" data-aa='2189424' src='//ad.a-ads.com/2189424?size=728x90' style={{ width: '728px', height: '90px', border: '0px', padding: 0, overflow: 'hidden', backgroundColor: 'transparent' }}></iframe>
                }
            </Box>
        </Box>
    )

}