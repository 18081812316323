
import {
    Box, CircularProgress, Divider, Paper, Typography,
} from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import Image from 'next/legacy/image';
//import { useTheme } from '@mui/material/styles';
//import { GridRow, GridColumnHeaders, DataGrid, gridClasses } from "@mui/x-data-grid";
//import axios from 'axios';
import useSWR, { preload } from 'swr';
import Chart24hr from './Chart24hr';
import RenderPrice from './RenderPrice';
import RenderChange from './RenderChange';
import { myLoader, stringShort, numShort } from '@/libs/fncs';
import { Launch } from '@mui/icons-material';
import RenderLogo from './RenderLogo';
import RenderDate from './RenderDate';
import Clink from '@/components/Clink';
import { useHookstate } from '@hookstate/core';
import { chaind, gainerd, globaldata, networkList, pageNod, paged, sidedatad, tableLoad } from '@/libs/store';
import { useTheme } from '@mui/system';
//import React, { useMemo } from "react";
const Decimal = require("decimal.js");

export default function GeneralTable({ url, router, data, sideData, pagetype, chainName, tableLoading, dataload, gainer, pageNo, mounted }) {

    /*     const MemoizedRow = React.memo(GridRow);
    const MemoizedColumnHeaders = React.memo(GridColumnHeaders);
    const MemoizedRow = useMemo(() => GridRow, []);
        const MemoizedColumnHeaders = useMemo(() => GridColumnHeaders, []); */
    //const data = useHookstate(globaldata);
    //const sideData = useHookstate(sidedatad);
    //const tableLoading = useHookstate(tableLoad);
    //const chainName = useHookstate(chaind);
    //const pageName = useHookstate(paged);
    const theme = useTheme();
    //const pageNo = useHookstate(pageNod);
    //const gainer = useHookstate(gainerd);

    //console.log("general", pagetype)

    const fetcher = url => fetch(url).then(async (res) => {
        res = { data: await res.json() };
        //var temp = pagetype == "dex" || pagetype == "token" ? res.data.data[0].result : res.data.data;
        var temp = res.data.data[0] && res.data.data[0].result ? res.data.data[0].result : [];
        if (pagetype == "topgainers") {
            await Promise.allSettled(temp[0].gainers.map(async (row, i) => {
                temp[0].gainers[i].id = i + 1;
            }))
            await Promise.allSettled(temp[0].losers.map(async (row, i) => {
                temp[0].losers[i].id = i + 1;
            }))
        } else {
            await Promise.allSettled(temp.map(async (row, i) => {
                temp[i].id = i + 1;
            }))
        }
        sideData.set(res.data.data[0] && res.data.data[0].side ? res.data.data[0].side : { chain: [{ symbol: 'all', blockNumber: 0, coinUSD: 0 }], gainers: [], losers: [], newpair: [] });

        //console.log(sideData.get());
        /* if (pagetype == "dex" || pagetype == "token") {
            sideData.set(res.data.data[0].side);
        } */
        //setTimeout(() => {
        //data[pagetype].set(temp);
        data.set(temp);
        tableLoading.set(false);
        //}, 1000);
        //console.log(data.get({ noproxy: true }));
        return res.data
    });

    preload(url, fetcher);

    useSWR(dataload.get() && mounted.get() ? url : null, fetcher, { refreshInterval: 20000 });

    const RenderTableName = (props) => {

        if (props.row && props.row.id) {
            var baseImage = null;
            var supportImage = null;
            var chainImage = props.row && props.row.chain && networkList[props.row.chain].image ? networkList[props.row.chain].image : "/sf/logo.svg";
            var symbol = "NA";
            var symbol1 = "NA";
            if (pagetype == "home" || pagetype == "dexlist") {
                baseImage = props.row && props.row.image && pagetype != "dexlist" ? props.row.image : null;
                symbol = props.row && props.row.symbol ? props.row.symbol : "NA";
            } else {
                baseImage = props.row && props.row.t0 ? props.row.t0.image : null;
                supportImage = props.row && props.row.t1 ? props.row.t1.image : null;
                symbol = props.row && props.row.t0 ? props.row.t0.symbol : "NA";
                symbol1 = props.row && props.row.t1 ? props.row.t1.symbol : "NA";
            }

            return (
                <Clink router={router} pageValue={pagetype == "home" ? "token" : pagetype == "dexlist" ? "dex" : "pair"} chainValue={props.row.chain} addValue={pagetype == "dexlist" ? props.row.router : props.row.address} href={`/${props.row.chain}/${pagetype == "home" ? "token" : pagetype == "dexlist" ? "dex" : "pair"}/${pagetype == "dexlist" ? props.row.router : props.row.address}`}>
                    <Box sx={{ width: "100%", display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                        <Box sx={{ display: 'flex', alignItems: "center" }}>
                            <Box sx={{ mr: 0.5, display: "flex", alignItems: "center", position: 'relative', width: "25px", height: "25px" }}>
                                <Image src={chainImage} alt="Logo" layout="fill" objectFit={'contain'} quality={70} priority={true} />
                            </Box>
                            <Box sx={{ mr: 0.5, display: "flex", alignItems: "center" }}>
                                <ArrowRightAlt />
                            </Box>
                            <Box sx={{ mr: 1, width: pagetype == "dexlist" || pagetype == "home" ? 25 : 54, display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                                <Box sx={{ display: "flex", alignItems: "center", position: 'relative', width: "25px", height: "25px" }}>
                                    <RenderLogo image={baseImage} value={pagetype == "dexlist" ? props.row.name : symbol} width={"25px"} height={"25px"} />
                                </Box>
                                <Box sx={{ display: pagetype == "dexlist" || pagetype == "home" ? "none" : "flex", alignItems: "center", position: 'relative', width: "25px", height: "25px" }}>
                                    {
                                        pagetype == "dexlist" || pagetype == "home" ? <></> :
                                            <RenderLogo image={supportImage} value={symbol1} width={"25px"} height={"25px"} />
                                    }
                                </Box>
                            </Box>
                            {
                                pagetype == "home" || pagetype == "dexlist" ?
                                    <Typography sx={{ whiteSpace: "nowrap" }}>{stringShort(props.row.name, 0, 10)}</Typography>
                                    :
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography sx={{ whiteSpace: "nowrap" }}>{stringShort(symbol, 0, 5)}</Typography>
                                        <Typography sx={{ whiteSpace: "nowrap", pl: 0.5, pr: 0.5 }}>/</Typography>
                                        <Typography sx={{ whiteSpace: "nowrap" }}>{stringShort(symbol1, 0, 5)}</Typography>
                                    </Box>
                            }
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: "center" }}>
                            {
                                pagetype != "home" ? <></> :
                                    <Box sx={{ color: theme.palette.primary.main, pl: 1, whiteSpace: "nowrap" }}>{stringShort(symbol, 0, 5)}</Box>
                            }
                            <Launch fontSize='small' sx={{ pl: 0.5 }} />
                        </Box>
                    </Box>
                </Clink>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const RenderTablePrice = (props) => {
        if (props.row && props.row.id) {
            //console.log(props)
            const usdValue = props.row && props.row.usdPrice ? props.row.usdPrice : 0;
            return (
                pagetype == "dexlist" ? <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center" }}>{stringShort(props.row.router, 1, 6)}</Box> :
                    <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center" }}>
                        <RenderPrice value={usdValue} usdtype={true} pdecimal={6} pfontsize={14} theme={theme} tool={false} />
                        {
                            pagetype == "newpair" || pagetype == "bigswap" ? <></> :
                                <Box sx={{ pl: 1 }} ><RenderChange ovalue={props.row.chart && props.row.chart.filter(n => n).length > 0 ? props.row.chart.filter(n => n)[0] : 0} nvalue={usdValue} tool={false} /> </Box>
                        }
                    </Box>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const RenderTableVolume = (props) => {
        if (props.row && props.row.id) {
            //console.log(props)
            var finalvalue = 0;
            if (pagetype == "home" || pagetype == "dexlist") {
                finalvalue = props.row && props.row.usdVolume24hr ? props.row.usdVolume24hr : 0;
            } else {
                finalvalue = props.row && props.row.t24h ? props.row.t24h.usdVolume : 0;
            }

            return (
                <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center" }}>
                    <RenderPrice value={finalvalue} usdtype={true} pdecimal={2} pfontsize={14} theme={theme} tool={false} />
                    <Box sx={{ color: theme.palette.primary.main, pl: 1 }}>{numShort(finalvalue, 2)}</Box>
                </Box>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const RenderTableCapital = (props) => {

        if (props.row && props.row.id) {
            var finalvalue = 0;
            if (pagetype != "home") {
                finalvalue = pagetype == "dexlist" ? (props.row && props.row.coinVolume24hr ? props.row.coinVolume24hr : 0) : (props.row && props.row.t24h ? props.row.t24h.coinVolume : 0);
            } else {
                const usdValue = props.row && props.row.usdPrice ? props.row.usdPrice : 0;
                if (pagetype == "home") {
                    finalvalue = props.row && props.row.totalSupply ? Decimal(props.row.totalSupply).div("1e" + props.row.decimals).mul(usdValue).toString() : 0;
                } else {
                    //finalvalue = props.row.reserve ? Decimal(Decimal(props.row.reserve.reserve0).div("1e" + props.row.t0.decimals)).add(Decimal(props.row.reserve.reserve1).div("1e" + props.row.t1.decimals))
                }
            }
            //console.log(props)
            return (
                <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center" }}>
                    <RenderPrice value={finalvalue} usdtype={pagetype == "home" ? true : false} pfontsize={14} theme={theme} tool={false} />
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <Box sx={{ color: theme.palette.primary.main, pl: 1 }}>{numShort(finalvalue, 2)}</Box>
                        {pagetype == "home" || chainName.get() != "all" ? <></> : <Box sx={{ pl: 0.5 }}>{stringShort(networkList[props.row && props.row.chain ? props.row.chain : 'all'].symbol.toUpperCase())}</Box>}
                    </Box>
                </Box>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const RenderTableSupply = (props) => {
        if (props.row && props.row.id) {
            var finalvalue = 0;
            var symbol = "NA";
            var symbol1 = "NA";

            if (pagetype == "bigswap") {
                finalvalue = props.row.t24h ? props.row.t24h.amount : 0;
                symbol = props.row && props.row.t0 ? props.row.t0.symbol.toUpperCase() : "NA";
                symbol1 = props.row && props.row.t1 ? props.row.t1.symbol.toUpperCase() : "NA";
            } else if (pagetype == "home") {
                finalvalue = props.row.totalSupply ? Decimal(props.row.totalSupply).div("1e" + props.row.decimals).toString() : 0;
            } else {
                finalvalue = pagetype == "dexlist" ? (props.row.txCount24hr ? props.row.txCount24hr : 0) : (props.row.t24h ? props.row.t24h.txCount : 0);
            }
            //console.log(props)
            return (
                <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center" }}>
                    <RenderPrice value={finalvalue} pdecimal={2} pfontsize={14} theme={theme} tool={false} />
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <Box sx={{ color: theme.palette.primary.main, pl: 1 }}>{numShort(finalvalue, 2)}</Box>
                        {pagetype == "bigswap" ? <Box sx={{ pl: 0.5 }}>{stringShort(props.row.type == "buy" ? symbol1 : symbol)}</Box> : <></>}
                    </Box>
                </Box>
            )
        } else {
            return (
                <></>
            )
        }
    }

    return (
        <Box sx={{ width: "100%", height: "100%", overflow: 'auto' }}>
            <Paper elevation={2} sx={{ width: "100%", minWidth: "1280px", height: "40px", position: 'sticky', top: 0, zIndex: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "40px", maxWidth: "40px", display: "flex", alignItems: "center", pl: 1, fontSize: 16 }}>
                        #
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "300px", display: "flex", alignItems: "center", pl: 1, pr: 1, fontSize: 16 }}>
                        {pagetype == "dexlist" ? 'DEX Name (Beta)' : pagetype == "home" ? 'Token' : "Pair"}
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "200px", display: "flex", alignItems: "center", pl: 1, pr: 1, fontSize: 16 }}>
                        {pagetype == "dexlist" ? 'Router' : 'Price'}
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "200px", display: "flex", alignItems: "center", pl: 1, pr: 1, fontSize: 16 }}>
                        Volume
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "200px", display: "flex", alignItems: "center", pl: 1, pr: 1, fontSize: 16 }}>
                        {pagetype != "home" ? `${chainName.get() != "all" ? networkList[chainName.get() ? chainName.get() : 'all'].symbol.toUpperCase() : ''} Volume` : 'Market Capital'}
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "200px", display: "flex", alignItems: "center", pl: 1, pr: 1, fontSize: 16 }}>
                        {pagetype != "home" ? pagetype == "bigswap" ? 'Amount' : 'Tx Count' : 'Total Supply'}
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "140px", display: "flex", alignItems: "center", maxWidth: "140px", fontSize: 16 }}>
                        {pagetype == "dexlist" ? 'Factory' : pagetype == "newpair" || pagetype == "bigswap" ? 'Created' : '24H Chart'}
                    </Typography>
                </Box>
            </Paper>
            <Box sx={{ width: "100%", height: `calc(100% - 40px)`, zIndex: 1 }}>
                {tableLoading.get() ?
                    <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                    :
                    (!data.get()[0] || (pagetype == "topgainers" && gainer.get() && !data.get()[0].gainers[0])) || (!data.get()[0] || (pagetype == "topgainers" && !gainer.get() && !data.get()[0].losers[0])) || (!data.get()[0]) ?
                        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", pl: 1 }}>
                            No Data Found
                        </Box>
                        :
                        (pagetype == "topgainers" ? gainer.get() ? data.get({ noproxy: true })[0] && data.get({ noproxy: true })[0].gainers ? data.get({ noproxy: true })[0].gainers : [] : data.get({ noproxy: true })[0] && data.get({ noproxy: true })[0].losers ? data.get({ noproxy: true })[0].losers : [] : data.get({ noproxy: true })[0] && data.get({ noproxy: true })[0].id ? data.get({ noproxy: true }) : []).map((row, i) => {
                            return (
                                <Box key={row.id} sx={{ width: "100%", minWidth: "1280px", height: "48px" }}>
                                    {
                                        row.id === 1 ? <></> : <Divider />
                                    }
                                    <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "40px", maxWidth: "40px", display: "flex", alignItems: "center", pl: 1 }}>
                                            <Typography variant='body2'>{parseInt(parseInt(pageNo.get() * 15) + row.id)}</Typography>
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "300px", pl: 1, pr: 1 }}>
                                            <RenderTableName row={row} />
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "200px", pl: 1, pr: 1 }}>
                                            <RenderTablePrice row={row} />
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "200px", pl: 1, pr: 1 }}>
                                            <RenderTableVolume row={row} />
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "200px", pl: 1, pr: 1 }}>
                                            <RenderTableCapital row={row} />
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "200px", pl: 1, pr: 1 }}>
                                            <RenderTableSupply row={row} />
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "140px", maxWidth: "140px" }}>
                                            {
                                                pagetype == "dexlist" ?
                                                    <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center" }}>{stringShort(row.factory, 1, 6)}</Box>
                                                    :
                                                    pagetype == "newpair" || pagetype == "bigswap" ?
                                                        <RenderDate value={row.timeStamp} />
                                                        :
                                                        mounted.get() ?
                                                            <Chart24hr chartid={row.id} chartdata={row.chart} />
                                                            : <></>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })
                }
            </Box>
        </Box>
    )
}
