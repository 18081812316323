import {
    Paper, Stack
} from '@mui/material';

import TrendingTable from './TrendingTable';

export default function Trending({ sideData, pageName, tableLoading, router }) {

    return (
        <Stack direction="row" spacing={0.5} sx={{ height: "100%", width: "100%" }}>
            <Paper sx={{ height: "100%", width: "100%", overflow: "hidden" }}>
                <TrendingTable trendtype={0} data={sideData} pageName={pageName} tableLoading={tableLoading} router={router} />
            </Paper>
            <Paper sx={{ height: "100%", width: "100%", overflow: "hidden" }}>
                <TrendingTable trendtype={1} data={sideData} pageName={pageName} tableLoading={tableLoading} router={router} />
            </Paper>
            <Paper sx={{ height: "100%", width: "100%", overflow: "hidden" }}>
                <TrendingTable trendtype={2} data={sideData} pageName={pageName} tableLoading={tableLoading} router={router} />
            </Paper>
        </Stack>
    )

}