import Clink from '@/components/Clink';
import { myLoader } from '@/libs/fncs';
import { Launch } from '@mui/icons-material';
import { ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    ListItemButton,
    Paper,
    Typography,
} from '@mui/material';
import Image from 'next/legacy/image';
import ReactPlayer from 'react-player';
import { createSvgIcon } from '@mui/material/utils';

/* const Network = createSvgIcon(
    <path d="M 12 2 A 2 2 0 0 0 10.005859 3.8789062 L 5.1015625 6.3320312 A 2 2 0 0 0 4 6 A 2 2 0 0 0 2 8 A 2 2 0 0 0 4 10 A 2 2 0 0 0 5.0996094 9.6679688 L 10.005859 12.121094 A 2 2 0 0 0 11 13.728516 L 11 18.269531 A 2 2 0 0 0 10.898438 18.332031 L 5.9941406 15.878906 A 2 2 0 0 0 4 14 A 2 2 0 0 0 2 16 A 2 2 0 0 0 4 18 A 2 2 0 0 0 5.0996094 17.667969 L 10.005859 20.121094 A 2 2 0 0 0 12 22 A 2 2 0 0 0 13.994141 20.119141 L 18.898438 17.667969 A 2 2 0 0 0 20 18 A 2 2 0 0 0 22 16 A 2 2 0 0 0 20 14 A 2 2 0 0 0 18.005859 15.878906 L 13.101562 18.332031 A 2 2 0 0 0 13 18.271484 L 13 13.728516 A 2 2 0 0 0 13.994141 12.121094 L 18.898438 9.6679688 A 2 2 0 0 0 20 10 A 2 2 0 0 0 22 8 A 2 2 0 0 0 20 6 A 2 2 0 0 0 18.005859 7.8789062 L 13.099609 10.332031 A 2 2 0 0 0 12 10 A 2 2 0 0 0 10.898438 10.332031 L 6.2363281 8 L 10.898438 5.6679688 A 2 2 0 0 0 12 6 A 2 2 0 0 0 14 4 A 2 2 0 0 0 12 2 z" />,
    'BlockChain',
); */

const BlockChain = createSvgIcon(
    <path d="M 12 2 C 11.194621 2 10.416027 2.3222541 9.8457031 2.8925781 L 7.4609375 5.2773438 L 6.7558594 5.9824219 L 2.8945312 9.8457031 C 2.3226384 10.416122 2 11.194621 2 12 C 2 12.80416 2.3200769 13.583271 2.8925781 14.154297 L 4.4023438 15.664062 L 5.2890625 16.550781 L 9.8457031 21.107422 C 10.416027 21.677746 11.194621 22 12 22 C 12.805379 22 13.58707 21.680152 14.158203 21.101562 L 19.427734 15.833984 L 21.109375 14.15625 L 21.111328 14.154297 C 21.671704 13.58948 21.996533 12.822981 22 12.019531 C 22.006866 11.203615 21.680104 10.418385 21.107422 9.8457031 L 20.339844 9.0800781 L 17.072266 5.8105469 L 14.158203 2.8945312 C 13.58578 2.3191428 12.805379 2 12 2 z M 12 4 C 12.288621 4 12.540657 4.104076 12.740234 4.3046875 L 12.742188 4.3066406 L 14.246094 5.8105469 L 11.828125 8.2304688 L 9.5820312 5.9824219 L 11.259766 4.3066406 C 11.463442 4.1029646 11.711379 4 12 4 z M 15.658203 7.2246094 L 17.599609 9.1679688 L 15.167969 11.572266 L 13.242188 9.6445312 L 15.658203 7.2246094 z M 8.1699219 7.3984375 L 10.414062 9.6445312 L 5.8144531 14.25 L 4.3046875 12.740234 L 4.3046875 12.738281 C 4.1011887 12.535354 4 12.28984 4 12 C 4 11.711379 4.1025336 11.4653 4.3066406 11.261719 L 4.3066406 11.259766 L 8.1699219 7.3984375 z M 19.015625 10.582031 L 19.693359 11.259766 C 19.900237 11.466643 20.002617 11.714496 20 12.003906 L 20 12.005859 L 20 12.009766 C 19.999 12.289633 19.894546 12.541359 19.691406 12.746094 L 18.013672 14.419922 L 16.582031 12.986328 L 19.015625 10.582031 z M 11.828125 11.060547 L 16.599609 15.833984 L 12.738281 19.695312 L 12.734375 19.697266 C 12.539503 19.89463 12.288621 20 12 20 C 11.711379 20 11.463442 19.897035 11.259766 19.693359 L 7.2304688 15.664062 L 11.828125 11.060547 z" />,
    'BlockChain',
);

export default function TelegramBot({ theme, networkList, router, mounted }) {

    const NetworkListRender = () => {
        const finallist = Object.values(networkList.get({ noproxy: true })).filter((e) => e.symbol != "all");
        //console.log("finallist", finallist);
        return (
            finallist.map((row, i) => {
                return (
                    <Box key={i} sx={{ p: 0.5 }}>
                        <Box sx={{ borderRadius: 2, p: 1 }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                {
                                    row.path == "all" ?
                                        <BlockChain />
                                        :
                                        <Box sx={{ width: "26px", height: "26px", position: "relative", borderRadius: 1, overflow: "hidden" }}>
                                            <Image loader={myLoader} unoptimized={true} src={row.image} alt={row.symbol} layout="fill" objectFit={'contain'} />
                                        </Box>
                                }
                                <Typography sx={{ fontSize: 15, pl: 1 }}>
                                    {`${row.name.toUpperCase()} (${row.symbol.toUpperCase()}) -> (${row.path.toUpperCase()})`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            })
        )
    }

    return (
        <Paper sx={{ height: "100%", overflow: "auto" }}>
            <Box sx={{ p: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {mounted.get() ? <ReactPlayer url='https://www.youtube.com/watch?v=dldmvzfjKjw' /> : <></>}
                </Box>

                <Typography variant="h6" sx={{ mt: 3 }}>{`Supported Networks -> Symbol`}</Typography>
                <NetworkListRender />

                <Typography variant="h6" sx={{ mt: 3 }}>How to setup?</Typography>
                <Box sx={{ mt: 1, mb: 1, p: 1, overflow: "auto" }}>
                    <Box sx={{ ml: 2, mt: 1, display: "flex" }}>
                        <Chip variant="outlined" label="Step 1" color="primary" size="small" sx={{ mr: 1 }} />
                        <Box>
                            You need to be CREATOR of the Telegram group.
                        </Box>
                    </Box>
                    <Box sx={{ ml: 2, mt: 1, display: "flex" }}>
                        <Chip variant="outlined" label="Step 2" color="primary" size="small" sx={{ mr: 1 }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box>
                                Add CryptoGems Bot to your group by clicking on the below link and set admin permission.
                            </Box>
                            <Clink router={router} href="https://t.me/cryptogemsinfo_bot" ext={true}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    CryptoGems Price Bot
                                    <Launch sx={{ fontSize: 18, ml: 0.5 }} />
                                </Box>
                            </Clink>
                        </Box>
                    </Box>
                    <Box sx={{ ml: 2, mt: 1, display: "flex" }}>
                        <Chip variant="outlined" label="Step 3" color="primary" size="small" sx={{ mr: 1 }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box>
                                (Optional) If you want to allow group admins to configure the bot insert <Chip label="/radmin all" size="small" sx={{ background: theme.palette.background.default }} /> command.
                            </Box>
                        </Box>
                    </Box>
                    {/*<Box sx={{ ml: 2, mt: 1, display: "flex" }}>
                        <Chip variant="outlined" label="Step 4" color="primary" size="small" sx={{ mr: 1 }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box>
                                (Optional) Register to get free advertisement points by calling <Chip label="/raddress <Your Address>" size="small" sx={{ background: theme.palette.background.default }} /> command.
                            </Box>
                            <Box >
                                You will receive free advertisement GEM points for the traffic received from your group at a 1:3 Ratio.
                            </Box>
                            <Typography sx={{ mt: 1 }}>Examples</Typography>
                            <Paper elevation={0} sx={{ p: 1, mb: 1, display: "flex", flexDirection: "column", whiteSpace: "nowrap" }}>
                                <Typography variant="subtitle2">
                                    {"/raddress 0x328F011f66619b5548466F9C89DA0664981247E8"}
                                </Typography>
                            </Paper>
                        </Box>
                    </Box>
                    <Box sx={{ ml: 2, mt: 1, display: "flex" }}>
                        <Chip variant="outlined" label="Step 5" color="primary" size="small" sx={{ mr: 1 }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box>
                                (Optional) Get Reference ID and use it along with the CryptoGems Pair and Token Links to earn free GEMS for the traffic that comes from it at a 1:3 Ratio. Call <Chip label="/getid" size="small" sx={{ background: theme.palette.background.default }} /> command to get your ID.
                            </Box>
                            <Box >
                                You can share this link on social media or set it as some custom bot DEX Chart Link.
                            </Box>
                            <Typography sx={{ mt: 1 }}>Example link formats, replace the refid and address with yours.</Typography>
                            <Paper elevation={0} sx={{ p: 1, mb: 1, display: "flex", flexDirection: "column", whiteSpace: "nowrap" }}>
                                <Typography variant="subtitle2">
                                    {"https://www.cryptogems.info/bsc/pair/0xdd3586fcaebe9674fdd32a8446a99c6ed047d4fa/?refid=3462245008"}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {"https://www.cryptogems.info/bsc/token/0xf3011f55836a9f3f3bd2f6efcddaf198764a3f73/?refid=3462245008"}
                                </Typography>
                            </Paper>
                        </Box>
                    </Box>*/}
                    <Box sx={{ ml: 2, mt: 1, display: "flex" }}>
                        <Chip variant="outlined" label="Step 4" color="primary" size="small" sx={{ mr: 1 }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box>
                                Config the bot for your token by calling <Chip label="/rpair <Contract Address> <Network Symbol>" size="small" sx={{ background: theme.palette.background.default }} /> command
                            </Box>
                            <Typography sx={{ mt: 1 }}>Examples</Typography>
                            <Paper elevation={0} sx={{ p: 1, mb: 1, display: "flex", flexDirection: "column", whiteSpace: "nowrap" }}>
                                <Typography variant="subtitle2">
                                    {"/rpair 0x7efaef62fddcca950418312c6c91aef321375a00 bsc"}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {"/rpair 0x7ee74e51d41c610df3030dc424744f31643b720b one"}
                                </Typography>
                            </Paper>
                            <Box>
                                Check below <Chip label="/rpair" size="small" sx={{ background: theme.palette.background.default }} /> command for more details.
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ ml: 2, mt: 1, display: "flex" }}>
                        <Chip variant="outlined" label="Step 5" color="primary" size="small" sx={{ mr: 1 }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box>
                                Setup default blockchain by calling <Chip label="/rchain <Network Symbol>" size="small" sx={{ background: theme.palette.background.default }} /> command.
                            </Box>
                            <Typography sx={{ mt: 1 }}>Examples</Typography>
                            <Paper elevation={0} sx={{ p: 1, mb: 1, display: "flex", flexDirection: "column", whiteSpace: "nowrap" }}>
                                <Typography variant="subtitle2">
                                    {"/rchain bsc"}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {"/rchain one"}
                                </Typography>
                            </Paper>
                        </Box>
                    </Box>
                    <Box sx={{ ml: 2, mt: 1, display: "flex" }}>
                        <Chip variant="outlined" label="Step 6" color="primary" size="small" sx={{ mr: 1 }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box>
                                {`It's all done! Telegram group members can call the bot with`} <Chip label="/price" size="small" sx={{ background: theme.palette.background.default }} /> or <Chip label="/price@cryptogemsinfo_bot" size="small" sx={{ background: theme.palette.background.default }} /> command to check market price.
                            </Box>
                            <Box>
                                Check below <Chip label="/price" size="small" sx={{ background: theme.palette.background.default }} /> command for more examples, if admin {"didn't"} set default pair.
                            </Box>
                        </Box>
                    </Box>

                    <Typography sx={{ mt: 1 }}>
                        For more options check below Public and Admin Commands.
                    </Typography>
                </Box>

                <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>Public Commands</Typography>
                <Paper elevation={4}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="start" id="a-start">
                            <Typography>/start</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="caption">Provides link to the bot command page.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="price" id="a-price">
                            <Typography>{"/price <Pair Address or Index Number> <Chain Symbol>"}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant="caption">{"Pair Address (Required if Admin not registered any default Pair Address) -> Provide DEX Pair Address."}</Typography>
                                <Typography variant="caption">{"Index Number (Optional once Admin Registered Default Pair Address) -> Provide Index Number, Which can be fetched using /pairlist command."}</Typography>
                                <Typography variant="caption">{"Chain Symbol (Optional, the default chain is bsc) -> Provide the chain symbol, which can be fetched using /chainlist command."}</Typography>
                                <Typography sx={{ mt: 2 }}>Examples</Typography>
                                <Paper elevation={0} sx={{ p: 1, display: "flex", flexDirection: "column", whiteSpace: "nowrap", overflow: "auto" }}>
                                    <Typography variant="subtitle2">
                                        {"/price"}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {"/price 0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae"}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {"/price 0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae bsc"}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {"/price 2"}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {"/price 1 one"}
                                    </Typography>
                                </Paper>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="pairlist" id="a-pairlist">
                            <Typography>/pairlist</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="caption">Provides the list of pairs for each chain which is registered by admin.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="chainlist" id="a-chainlist">
                            <Typography>/chainlist</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="caption">Provides the list of chain symbol which is supported by CryptoGems.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="defaultchain" id="a-defaultchain">
                            <Typography>/defaultchain</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="caption">Provides the default chain which is registered by admin.</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>


                <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>Admin Commands</Typography>
                <Paper elevation={4}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="rpair" id="a-rpair">
                            <Typography>{"/rpair <Pair Address> <Chain Symbol>"}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant="caption">{"Pair Address -> Provide the list of pair address seperated by comma without space."}</Typography>
                                <Typography variant="caption">{"Chain Symbol (Optional) -> Provide the chain symbol where the pairs need to be registered, the default chain is bsc if not provided."}</Typography>

                                <Typography sx={{ mt: 2 }}>Examples</Typography>
                                <Paper elevation={0} sx={{ p: 1, display: "flex", flexDirection: "column", whiteSpace: "nowrap", overflow: "auto" }}>
                                    <Typography variant="subtitle2">
                                        {"/rpair 0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae"}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {"/rpair 0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae,0x58f876857a02d6762e0101bb5c46a8c1ed44dc16"}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {"/rpair 0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae,0x58f876857a02d6762e0101bb5c46a8c1ed44dc16 bsc"}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {"/rpair 0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae,0x58f876857a02d6762e0101bb5c46a8c1ed44dc16,0xb4ec801aed8c92f2e69589518aaa127afb37d8c9"}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {"/rpair 0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae one"}
                                    </Typography>
                                </Paper>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="raddress" id="a-raddress">
                            <Typography>{"/raddress <Ethereum Address>"}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="caption">{"Ethereum Address (Required) -> Provide any valid ethereum address to participate in the advertisement program, where you get free GEMS for the traffic received from your group at 1:3 Ratio."}</Typography>

                            <Typography sx={{ mt: 2 }}>Examples</Typography>
                            <Paper elevation={0} sx={{ p: 1, display: "flex", flexDirection: "column", whiteSpace: "nowrap", overflow: "auto" }}>
                                <Typography variant="subtitle2">
                                    {"/raddress 0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae"}
                                </Typography>
                            </Paper>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="getid" id="a-getid">
                            <Typography>{"/getid"}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="caption">Displays your reference Id, which can be used in other bots to receive free advertisement credits for the traffic comes through it.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="radmin" id="a-radmin">
                            <Typography>{"/radmin <all or creator>"}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant="caption">{"all -> Allows all admins to make modification to the bot."}</Typography>
                                <Typography variant="caption">{"creator -> Only the group creator is allowed to make modification, default creator is allowed."}</Typography>

                                <Typography sx={{ mt: 2 }}>Examples</Typography>
                                <Paper elevation={0} sx={{ p: 1, display: "flex", flexDirection: "column", whiteSpace: "nowrap", overflow: "auto" }}>
                                    <Typography variant="subtitle2">
                                        {"/radmin all"}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {"/radmin creator"}
                                    </Typography>
                                </Paper>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="rchain" id="a-rchain">
                            <Typography>{"/rchain <Chain Symbol>"}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="caption">{"Chain Symbol (Required) -> Provide the chain symbol where all request will be made, the default chain is bsc."}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="removeallpair" id="a-removeallpair">
                            <Typography>{"/removeallpair <all or Chain Symbol>"}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant="caption">{"all (Required) -> Remove all pairs from all chains."}</Typography>
                                <Typography variant="caption">{"Chain Symbol (Required) -> Provide the chain symbol to remove pairs related to it."}</Typography>

                                <Typography sx={{ mt: 2 }}>Examples</Typography>
                                <Paper elevation={0} sx={{ p: 1, display: "flex", flexDirection: "column", whiteSpace: "nowrap", overflow: "auto" }}>
                                    <Typography variant="subtitle2">
                                        {"/removeallpair all"}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {"/removeallpair bsc"}
                                    </Typography>
                                </Paper>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="adminstatus" id="a-adminstatus">
                            <Typography>/adminstatus</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="caption">Provides the default admin info who can make modification to the bot.</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Box>
        </Paper>
    )

}