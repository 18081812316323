import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import {
    Alert,
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Skeleton,
    Snackbar,
    Typography,
} from '@mui/material';
import { useHookstate } from '@hookstate/core';
import dynamic from 'next/dynamic';
import { popStatusd, addressd, adtyped, adviewd, alertd, chaind, gainerd, globaldata, historyDatad, marketd, mountedd, networkd, paged, pageNod, seod, sideaccord, sidedatad, swapsd, tableLoad, themed, tvwd, viewd, widthd, popTyped, apiUrl, walletd, favsd, stickyd, isApp, dataloadd } from '@/libs/store';
import Script from 'next/script';

//import SideTopAd from './SideTopAd';

import TopBigAd from './TopBigAd';
import SideBottomAd from './SideBottomAd';
import GeneralTable from './GeneralTable';
import HeaderTitle from './HeaderTitle';
import Navigator from './Navigator';
import Trending from './Trending';
import SideMenu from './SideMenu';
import BottomMenu from './BottomMenu';
import SideContent from './SideContent';
import PairHistory from './PairHistory';
import TelegramBot from './TelegramBot';
import Privacy from './Privacy';
import Terms from './Terms';
import Partners from './Partners';
//import RenderPairChart from './RenderPairChart';
//import DialogRender from './DialogRender';

/*const TopBigAd = dynamic(() => import('./TopBigAd'), { ssr: true });
const SideBottomAd = dynamic(() => import('./SideBottomAd'), { ssr: true });
const GeneralTable = dynamic(() => import('./GeneralTable'), { ssr: true });
const HeaderTitle = dynamic(() => import('./HeaderTitle'), { ssr: true });
const Navigator = dynamic(() => import('./Navigator'), { ssr: true });
const Trending = dynamic(() => import('./Trending'), { ssr: true });
const SideMenu = dynamic(() => import('./SideMenu'), { ssr: true });
const BottomMenu = dynamic(() => import('./BottomMenu'), { ssr: true });
const SideContent = dynamic(() => import('./SideContent'), { ssr: true });
const PairHistory = dynamic(() => import('./PairHistory'), { ssr: true });
const TelegramBot = dynamic(() => import('./TelegramBot'), { ssr: true });
const Privacy = dynamic(() => import('./Privacy'), { ssr: true });
const Terms = dynamic(() => import('./Terms'), { ssr: true });*/
const RenderPairChart = dynamic(() => import('./RenderPairChart'), { ssr: false });
const DialogRender = dynamic(() => import('./DialogRender'), { ssr: false });


export default function RootLayout({ router, pagetype, chain, addressData, apidata, apisidedata, marketData }) {

    const mounted = useHookstate(mountedd);
    const dataload = useHookstate(dataloadd);
    const selAcc = useHookstate(pagetype == 'pair' || pagetype == 'dex' || pagetype == 'token' ? 1 : 2);
    const data = useHookstate(apidata && apidata[0] ? apidata : []);
    const historyData = useHookstate(apidata && apidata[0] ? apidata : []);
    const sideData = useHookstate(apisidedata ? apisidedata : { chain: [{ symbol: 'all', blockNumber: 0, coinUSD: 0 }], gainers: [], losers: [], newpair: [] });// ? apisidedata : sidedatad.get({ noproxy: true })
    const tableLoading = useHookstate(false);
    const views = useHookstate({
        top: true,
        center: true,
        bottom: (pagetype == "bot" || pagetype == "privacy" || pagetype == "partners" || pagetype == "terms") ? false : true,
        side: true
    });
    const themeMode = useHookstate(themed);
    const chainName = useHookstate(chain ? chain : 'all');
    const pageName = useHookstate(pagetype ? pagetype : "home");
    const networkList = useHookstate(networkd);
    const alerting = useHookstate(alertd);
    const theme = useTheme();
    const widths = useHookstate(widthd);
    const menuStatus = useHookstate("none");
    const sideMenuStatus = useHookstate("none");
    const seo = useHookstate(seod);
    const adviewable = useHookstate(adviewd);
    const adtype = useHookstate(adtyped);
    const pageNo = useHookstate(pageNod);
    const popStatus = useHookstate(popStatusd);
    const gainer = useHookstate(gainerd);
    const address = useHookstate(addressData ? addressData : '');
    const tvw = useHookstate(tvwd);
    const swaps = useHookstate(swapsd);
    const market = useHookstate(marketData);
    const popType = useHookstate(popTyped);
    const wallet = useHookstate(walletd);
    const favList = useHookstate(favsd);
    const sticky = useHookstate(stickyd);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        alerting.status.set(false);
    };

    return (
        <Box sx={{ width: "100%", height: "100%", p: 0.5 }}>
            <Snackbar open={alerting.get().status} anchorOrigin={{ horizontal: "center", vertical: "top" }} autoHideDuration={alerting.get().duration} onClose={handleClose}>
                <Alert variant="filled" severity={alerting.get().type} onClose={handleClose} sx={{ width: '100%' }}>
                    {alerting.get().message}
                </Alert>
            </Snackbar>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>

                <Box sx={{ display: { xs: "block", md: "none" }, height: { xs: "50px", md: 0 }, width: { xs: "100%", md: 0 }, pb: 0.5 }}>
                    <BottomMenu menuStatus={menuStatus} pageName={pageName} themeMode={themeMode} sideMenuStatus={sideMenuStatus} tvw={tvw} selAcc={selAcc} popStatus={popStatus} popType={popType} wallet={wallet} />
                </Box>

                <Box sx={{ display: "flex", width: "100%", height: { xs: `calc(100% - 50px)`, md: "100%" } }}>
                    <Box sx={{ display: { xs: menuStatus.get(), md: "block" }, width: { xs: menuStatus.get() == "none" ? 0 : "100%", md: "50px" }, height: { xs: menuStatus.get() == "none" ? 0 : `calc(100% - 50px)`, md: "100%" } }}>
                        <SideMenu pageNo={pageNo} menuStatus={menuStatus} chainName={chainName} pageName={pageName} theme={theme} themeMode={themeMode} tableLoading={tableLoading} seo={seo} tvw={tvw} sideMenuStatus={sideMenuStatus} router={router} popType={popType} popStatus={popStatus} wallet={wallet} views={views} />
                    </Box>


                    <Box sx={{ width: { xs: "100%", md: `calc(100% - ${views.get().side ? 370 : 50}px)` }, height: "100%", pl: { xs: 0, md: 0.5 }, pr: { xs: 0, md: views.get().side ? 0.5 : 0 } }}>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>

                            <Box sx={{ display: adviewable.get() ? "flex" : "none", justifyContent: "center", alignItems: "center", width: "100%", height: adviewable.get() ? { xs: "50px", md: "90px" } : 0 }}>
                                <TopBigAd adtype={adtype} />
                            </Box>

                            <Box sx={{ width: "100%", height: "50px", pt: adviewable.get() ? 0.5 : 0, pb: 0.5 }}>
                                {
                                    <HeaderTitle pageTitle={seo.get()[pagetype].title} pagetype={pagetype} views={views} widths={widths} gainer={gainer} chainName={chainName} networkList={networkList} address={address} tvw={tvw} sideData={sideData} market={market} favList={favList} alerting={alerting} wallet={wallet} chain={chain} />
                                }
                            </Box>


                            <Box sx={{ display: views.get().center ? "block" : "none", width: "100%", height: (pagetype == "bot" || pagetype == "privacy" || pagetype == "partners" || pagetype == "terms") ? { xs: `calc(100% - ${adviewable.get() ? 100 : 50}px)`, md: `calc(100% - ${adviewable.get() ? 140 : 50}px)` } : views.get().center && views.get().bottom ? { xs: `calc(100% - ${adviewable.get() ? 340 : 290}px)`, md: `calc(100% - ${adviewable.get() ? 446 : 356}px)` } : views.get().center ? { xs: `calc(100% - ${adviewable.get() ? (pagetype == "bot" || pagetype == "privacy" || pagetype == "partners" || pagetype == "terms") ? 100 : 140 : (pagetype == "bot" || pagetype == "privacy" || pagetype == "partners" || pagetype == "terms") ? 50 : 90}px)`, md: `calc(100% - ${adviewable.get() ? (pagetype == "bot" || pagetype == "privacy" || pagetype == "partners" || pagetype == "terms") ? 140 : 180 : (pagetype == "bot" || pagetype == "privacy" || pagetype == "partners" || pagetype == "terms") ? 50 : 90}px)` } : 0 }}>
                                <Paper sx={{ width: "100%", height: "100%", overflow: "auto" }}>
                                    {
                                        pagetype == "bot" ?
                                            <TelegramBot theme={theme} networkList={networkList} router={router} mounted={mounted} />
                                            :
                                            pagetype == "privacy" ?
                                                <Privacy />
                                                :
                                                pagetype == "partners" ?
                                                    <Partners />
                                                    :
                                                    pagetype == "terms" ?
                                                        <Terms />
                                                        :
                                                        pagetype == "pair" ?
                                                            mounted.get() ?
                                                                <RenderPairChart chainName={chainName} themeMode={themeMode} address={address} tvw={tvw} market={market} widths={widths} />
                                                                : <></>
                                                            :
                                                            <GeneralTable url={`${apiUrl}/${pagetype ? pagetype == "home" ? "tokenlist" : pagetype : 'tokenlist'}?chain=${chainName.get() ? chainName.get() : 'all'}&offset=${pageNo.get() ? pageNo.get() : 0}&address=${pagetype == "dex" || pagetype == "token" ? address.get() : ""}`} router={router} data={data} sideData={sideData} pagetype={pagetype} chainName={chainName} tableLoading={tableLoading} dataload={dataload} gainer={gainer} pageNo={pageNo} mounted={mounted} />
                                    }
                                </Paper>
                            </Box>


                            <Box sx={{ display: (pagetype == "bot" || pagetype == "privacy" || pagetype == "partners" || pagetype == "terms") ? "none" : "block", width: "100%", height: (pagetype == "bot" || pagetype == "privacy" || pagetype == "partners" || pagetype == "terms") && (views.get().center && !views.get().bottom) ? 0 : "40px", pt: views.get().center ? 0.5 : 0, pb: views.get().bottom ? 0.5 : 0 }}>
                                {
                                    <Navigator views={views} pageName={pageName} widths={widths} pageNo={pageNo} tableLoading={tableLoading} swaps={swaps} wallet={wallet} alerting={alerting} dataload={dataload} historyData={historyData} />
                                }
                            </Box>

                            <Box sx={{ display: (pagetype == "bot" || pagetype == "privacy" || pagetype == "partners" || pagetype == "terms" || !views.get().bottom) ? "none" : views.get().bottom ? "block" : "none", width: "100%", height: views.get().center && views.get().bottom ? { xs: "200px", md: "266px" } : views.get().bottom ? { xs: `calc(100% - ${adviewable.get() ? 140 : 90}px)`, md: `calc(100% - ${adviewable.get() ? 180 : 90}px)` } : 0 }}>
                                <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
                                    {
                                        pagetype == "pair" ?
                                            <PairHistory url={`${apiUrl}/${pagetype}?chain=${chainName.get()}&offset=${pageNo.get()}&address=${address.get()}&pairtype=${swaps.get()}&useradd=${wallet.get().address}`} sideData={sideData} mounted={mounted} networkList={networkList} chainName={chainName} theme={theme} historyData={historyData} tableLoading={tableLoading} seo={seo} market={market} pageName={pageName} router={router} tvw={tvw} views={views} pagetype={pagetype} chain={chain} addressData={addressData} dataload={dataload} />
                                            :
                                            <Trending sideData={sideData} pageName={pageName} tableLoading={tableLoading} router={router} />
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>


                    <Box sx={{ display: { xs: sideMenuStatus.get(), md: views.get().side ? "block" : "none" }, width: { xs: (sideMenuStatus.get() == "none" ? 0 : `calc(100% - 8px)`), md: views.get().side ? "320px" : 0 }, height: { xs: (sideMenuStatus.get() == "none" ? 0 : `calc(100% - 58px)`), md: "100%" }, background: theme.palette.background.default, position: { xs: "fixed", md: "static" }, zIndex: 5 }}>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>


                            {/* <Box sx={{ display: adviewable.get() ? "block" : "none", width: "100%", height: adviewable.get() ? "50px" : 0 }}>
                                        <SideTopAd adtype={adtype} />
                            </Box> */}


                            <Box sx={{ width: "100%", height: adviewable.get() ? { xs: `calc(100% - 250px)`, md: `calc(100% - 266px)` } : "100%", /* pt: adviewable.get() ? 0.5 : 0, */ pb: adviewable.get() ? 0.5 : 0 }}>
                                <SideContent theme={theme} selAcc={selAcc} networkList={networkList} chainName={chainName} pageName={pageName} tableLoading={tableLoading} sideData={sideData} sideMenuStatus={sideMenuStatus} router={router} favList={favList} alerting={alerting} wallet={wallet} />
                            </Box>


                            <Box sx={{ display: adviewable.get() ? "block" : "none", width: "100%", height: adviewable.get() ? { xs: "250px", md: "266px" } : 0 }}>
                                <SideBottomAd adtype={adtype} />
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </Box>


            <Dialog
                open={popStatus.get()}
                onClose={() => { }}
                PaperProps={{ sx: { width: { xs: "100%", md: 500 }, m: 0.5 } }}
                //BackdropProps={{ invisible: true }}
                disableScrollLock
                disableEnforceFocus
                aria-labelledby="ad-blocker"
                aria-describedby="ad-blocker-cryptogems"
            >
                <DialogRender popType={popType} wallet={wallet} popStatus={popStatus} themeMode={themeMode} theme={theme} router={router} chainName={chainName} pageName={pageName} address={address} favList={favList} alerting={alerting} />
            </Dialog>
            {
                sticky.get() && !isApp ?
                    <Box sx={{ width: 0, height: 0 }}>
                        <ins className="64c8e7a64485b30b6fb7fecc" style={{ display: 'inline-block', width: '0px', height: '0px' }}></ins>
                        <Script id='bmsticky'>
                            {
                                `
                                setTimeout(() => {
                                    !function(e,n,c,t,o,r,d){!function e(n,c,t,o,r,m,d,s,a){s=c.getElementsByTagName(t)[0],(a=c.createElement(t)).async=!0,a.src="https://"+r[m]+"/js/"+o+".js?v="+d,a.onerror=function(){a.remove(),(m+=1)>=r.length||e(n,c,t,o,r,m)},s.parentNode.insertBefore(a,s)}(window,document,"script","64c8e7a64485b30b6fb7fecc",["cdn.bmcdn6.com"], 0, new Date().getTime())}();
                                }, ${router.asPath == '/' ? 20000 : 20000});
                                `
                            }
                        </Script>
                    </Box>
                    :
                    <></>
            }
        </Box >
    )
}
