import Clink from '@/components/Clink';
import { myLoader, numFormat, numShort, stringAvatar, stringShort } from '@/libs/fncs';
import { ContentCopy, FindInPage, Numbers, OpenInNew } from '@mui/icons-material';
import { Done } from '@mui/icons-material';
import { Favorite } from '@mui/icons-material';
import { ExpandLess } from '@mui/icons-material';
import { ExpandMore } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Chip,
    Divider,
    ListItemButton,
    Paper,
    Tooltip,
    Typography
} from '@mui/material';
import Image from 'next/legacy/image';
import SideContentCard from '@/components/SideContentCard';
import RenderPrice from './RenderPrice';
import AddressRender from './AddressRender';
import { JoinRight } from '@mui/icons-material';
import { baseUrl, networkList } from '@/libs/store';
import RenderChange from './RenderChange';
import { Token } from '@mui/icons-material';
import { AccountBalance } from '@mui/icons-material';
import RenderLogo from './RenderLogo';
const Decimal = require("decimal.js");
import { createSvgIcon } from '@mui/material/utils';
import { Explore } from '@mui/icons-material';
import { Language } from '@mui/icons-material';
import { Twitter } from '@mui/icons-material';
import { Facebook } from '@mui/icons-material';
import { Telegram } from '@mui/icons-material';
import { Reddit } from '@mui/icons-material';
import PairTable from './PairTable';


const BlockChain = createSvgIcon(
    <path d="M 12 2 C 11.194621 2 10.416027 2.3222541 9.8457031 2.8925781 L 7.4609375 5.2773438 L 6.7558594 5.9824219 L 2.8945312 9.8457031 C 2.3226384 10.416122 2 11.194621 2 12 C 2 12.80416 2.3200769 13.583271 2.8925781 14.154297 L 4.4023438 15.664062 L 5.2890625 16.550781 L 9.8457031 21.107422 C 10.416027 21.677746 11.194621 22 12 22 C 12.805379 22 13.58707 21.680152 14.158203 21.101562 L 19.427734 15.833984 L 21.109375 14.15625 L 21.111328 14.154297 C 21.671704 13.58948 21.996533 12.822981 22 12.019531 C 22.006866 11.203615 21.680104 10.418385 21.107422 9.8457031 L 20.339844 9.0800781 L 17.072266 5.8105469 L 14.158203 2.8945312 C 13.58578 2.3191428 12.805379 2 12 2 z M 12 4 C 12.288621 4 12.540657 4.104076 12.740234 4.3046875 L 12.742188 4.3066406 L 14.246094 5.8105469 L 11.828125 8.2304688 L 9.5820312 5.9824219 L 11.259766 4.3066406 C 11.463442 4.1029646 11.711379 4 12 4 z M 15.658203 7.2246094 L 17.599609 9.1679688 L 15.167969 11.572266 L 13.242188 9.6445312 L 15.658203 7.2246094 z M 8.1699219 7.3984375 L 10.414062 9.6445312 L 5.8144531 14.25 L 4.3046875 12.740234 L 4.3046875 12.738281 C 4.1011887 12.535354 4 12.28984 4 12 C 4 11.711379 4.1025336 11.4653 4.3066406 11.261719 L 4.3066406 11.259766 L 8.1699219 7.3984375 z M 19.015625 10.582031 L 19.693359 11.259766 C 19.900237 11.466643 20.002617 11.714496 20 12.003906 L 20 12.005859 L 20 12.009766 C 19.999 12.289633 19.894546 12.541359 19.691406 12.746094 L 18.013672 14.419922 L 16.582031 12.986328 L 19.015625 10.582031 z M 11.828125 11.060547 L 16.599609 15.833984 L 12.738281 19.695312 L 12.734375 19.697266 C 12.539503 19.89463 12.288621 20 12 20 C 11.711379 20 11.463442 19.897035 11.259766 19.693359 L 7.2304688 15.664062 L 11.828125 11.060547 z" />,
    'BlockChain',
);

const Network = createSvgIcon(
    <path d="M 12 2 A 2 2 0 0 0 10.005859 3.8789062 L 5.1015625 6.3320312 A 2 2 0 0 0 4 6 A 2 2 0 0 0 2 8 A 2 2 0 0 0 4 10 A 2 2 0 0 0 5.0996094 9.6679688 L 10.005859 12.121094 A 2 2 0 0 0 11 13.728516 L 11 18.269531 A 2 2 0 0 0 10.898438 18.332031 L 5.9941406 15.878906 A 2 2 0 0 0 4 14 A 2 2 0 0 0 2 16 A 2 2 0 0 0 4 18 A 2 2 0 0 0 5.0996094 17.667969 L 10.005859 20.121094 A 2 2 0 0 0 12 22 A 2 2 0 0 0 13.994141 20.119141 L 18.898438 17.667969 A 2 2 0 0 0 20 18 A 2 2 0 0 0 22 16 A 2 2 0 0 0 20 14 A 2 2 0 0 0 18.005859 15.878906 L 13.101562 18.332031 A 2 2 0 0 0 13 18.271484 L 13 13.728516 A 2 2 0 0 0 13.994141 12.121094 L 18.898438 9.6679688 A 2 2 0 0 0 20 10 A 2 2 0 0 0 22 8 A 2 2 0 0 0 20 6 A 2 2 0 0 0 18.005859 7.8789062 L 13.099609 10.332031 A 2 2 0 0 0 12 10 A 2 2 0 0 0 10.898438 10.332031 L 6.2363281 8 L 10.898438 5.6679688 A 2 2 0 0 0 12 6 A 2 2 0 0 0 14 4 A 2 2 0 0 0 12 2 z" />,
    'BlockChain',
);

const Cube = createSvgIcon(
    <path d="M20.922,7.615c-0.051-0.122-0.124-0.231-0.216-0.323l-3.999-3.999C16.52,3.105,16.265,3,16,3H4 C3.87,3,3.74,3.026,3.618,3.077c-0.245,0.101-0.44,0.296-0.541,0.541C3.027,3.74,3,3.87,3,4v12c0,0.266,0.105,0.52,0.293,0.707 l3.999,3.999c0.092,0.092,0.201,0.165,0.323,0.216C7.736,20.973,7.867,21,8,21h12c0.552,0,1-0.447,1-1V8 C21,7.866,20.973,7.736,20.922,7.615z M17.586,7H8.414l-2-2h9.172L17.586,7z M9,19V9h10v10H9z" />,
    'BlockChain',
);

export default function SideContent({ selAcc, sideMenuStatus, chainName, pageName, tableLoading, wallet, sideData, theme, router, favList, alerting }) {

    const chainDetails = networkList[chainName.get() ? chainName.get() : 'all'];

    const NetworkListRender = () => {
        const finallist = Object.values(networkList);//.filter((e) => e.symbol != "all")
        //console.log("finallist", finallist);
        return (
            finallist.map((row, i) => {
                return (
                    <Box key={i} sx={{ width: `100%` }}>
                        <Clink router={router} chainValue={row.path} pageValue={pageName.get() == 'home' || pageName.get() == "bot" || pageName.get() == "privacy" || pageName.get() == "partners" || pageName.get() == "terms" || pageName.get() == 'dex' || pageName.get() == 'pair' || pageName.get() == 'token' ? 'home' : pageName.get()} href={pageName.get() == 'home' || pageName.get() == "bot" || pageName.get() == "privacy" || pageName.get() == "partners" || pageName.get() == "terms" || pageName.get() == 'dex' || pageName.get() == 'pair' || pageName.get() == 'token' ? `/${row.path == 'all' ? '' : row.path}` : `/${row.path}/${pageName.get()}`} tableLoading={tableLoading} >
                            <ListItemButton selected={chainName.get() === row.path} onClick={() => {
                                sideMenuStatus.set("none");
                            }} sx={{ width: "100%", height: "100%" }}>
                                <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                    <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
                                        {
                                            row.path == "all" ?
                                                <BlockChain />
                                                :
                                                <Box sx={{ width: "26px", height: "26px", position: "relative", borderRadius: 1, overflow: "hidden" }}>
                                                    <Image src={row.image} alt={row.symbol} layout="fill" objectFit={'contain'} quality={70} priority={true} />
                                                </Box>
                                        }
                                        <Typography sx={{ fontSize: 15, pl: 1 }}>
                                            {`${row.name.toUpperCase()} (${row.symbol.toUpperCase()})`}
                                        </Typography>
                                    </Box>
                                    {
                                        chainName.get() == row.path ?
                                            <Done fontSize='small' sx={{ color: "green" }} />
                                            :
                                            <Box></Box>
                                    }
                                </Box>
                            </ListItemButton>
                        </Clink>
                    </Box>
                )
            })
        )
    }

    const TokenInfoContent = () => {
        return (
            <Box sx={{ width: "100%", height: "100%" }}>
                <SideContentCard title={stringShort(sideData.get() && sideData.get().name ? sideData.get().name : 'NA', 0, 15)} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", height: "100%", width: "100%", justifyContent: "space-between" }}>
                        <Chip
                            label={"Token CA"}
                            size='small'
                        />
                        <AddressRender router={router} address={sideData.get() && sideData.get().address ? sideData.get().address : 'NA'} addlen={8} link={sideData.get() && sideData.get().address ? `${chainDetails.token_link}${sideData.get().address}` : ''} ext={true} />
                    </Box>
                </SideContentCard>
                <SideContentCard title={"Token Symbol & Decimal"} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", height: "100%", width: "100%", justifyContent: "space-between" }}>
                        <Chip
                            label={<Typography fontSize={12}>{stringShort(sideData.get() && sideData.get().symbol ? sideData.get().symbol : 'NA', 0, 10)}</Typography>}
                            size='small'
                            icon={
                                sideData.get() && sideData.get().image ?
                                    <Box sx={{ width: "16px", height: "16px", position: "relative", borderRadius: 1, overflow: "hidden" }}>
                                        <Image loader={myLoader} unoptimized={true} src={sideData.get() && sideData.get().image ? sideData.get().image : '/logo.svg'} alt={sideData.get() && sideData.get().symbol ? sideData.get().symbol : 'NA'} layout="fill" objectFit={'contain'} />
                                    </Box>
                                    :
                                    <Avatar style={{ width: "16px", height: "16px", fontSize: "16px", fontWeight: "500" }} {...stringAvatar(sideData.get() && sideData.get().symbol ? sideData.get().symbol : 'NA')}></Avatar>
                            }
                        />
                        <Chip
                            label={<Typography fontSize={12}>Decimals {sideData.get() && sideData.get().decimals ? sideData.get().decimals : 0}</Typography>}
                            size='small'
                        />
                    </Box>
                </SideContentCard>
                <SideContentCard title={"Token Total Supply"} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", height: "100%", width: "100%", justifyContent: "space-between" }}>
                        <Tooltip title={Decimal(sideData.get() && sideData.get().totalSupply ? sideData.get().totalSupply : 0).div(`1e${sideData.get() && sideData.get().decimals ? sideData.get().decimals : 0}`).toString()} placement="top" sx={{ overflow: "auto" }}>
                            <Typography fontSize={14}>{stringShort(numFormat(Decimal(sideData.get() && sideData.get().totalSupply ? sideData.get().totalSupply : 0).div(`1e${sideData.get() && sideData.get().decimals ? sideData.get().decimals : 0}`).toString()), 0, 20)}</Typography>
                        </Tooltip>
                        <Chip
                            label={<Typography fontSize={12}>{`${numShort(Decimal(sideData.get() && sideData.get().totalSupply ? sideData.get().totalSupply : 0).div(`1e${sideData.get() && sideData.get().decimals ? sideData.get().decimals : 0}`).toString())} ${sideData.get() && sideData.get().symbol ? sideData.get().symbol : "NA"}`}</Typography>}
                            size='small'
                        />
                    </Box>
                </SideContentCard>
                <SideContentCard title={"Token 24H Volume"} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <RenderPrice value={sideData.get() && sideData.get().t24h ? sideData.get().t24h.usdVolume : 0} pdecimal={4} usdtype={true} pfontsize={14} theme={theme} stringLen={6} />
                            <Chip
                                sx={{ ml: 0.5 }}
                                label={`${numShort(sideData.get() && sideData.get().t24h ? sideData.get().t24h.usdVolume : 0)} USD`}
                                size='small'
                            />
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <RenderPrice value={sideData.get() && sideData.get().t24h ? sideData.get().t24h.coinVolume : 0} pfontsize={14} theme={theme} stringLen={6} />
                            <Chip
                                sx={{ ml: 0.5 }}
                                label={`${numShort(sideData.get() && sideData.get().t24h ? sideData.get().t24h.coinVolume : 0)} ${stringShort(sideData.get() && sideData.get().chain && sideData.get().chain[0] ? sideData.get().chain[0].symbol.toUpperCase() : chainName.get().toUpperCase())}`}
                                size='small'
                            />
                        </Box>
                    </Box>
                </SideContentCard>
                <SideContentCard title={"Token Useful Links"} theme={theme} bottom={0.5} height={196} >
                    <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Chip
                                label={`Website`}
                                size='small'
                                icon={<Language />}
                            />
                            {sideData.get() && sideData.get().links && sideData.get().links.homepage[0] ? <AddressRender router={router} address={sideData.get().links.homepage[0]} addlen={5} link={sideData.get().links.homepage[0]} ext={true} /> : <Typography fontSize={12}>NA</Typography>}
                        </Box>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", pt: 0.5 }}>
                            <Chip
                                label={`Explorer`}
                                size='small'
                                icon={<Explore />}
                            />
                            {sideData.get() && sideData.get().address ? <AddressRender router={router} address={`${chainDetails.token_link}${sideData.get().address}`} addlen={5} link={`${chainDetails.token_link}${sideData.get().address}`} ext={true} /> : <Typography fontSize={12}>NA</Typography>}
                        </Box>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", pt: 0.5 }}>
                            <Chip
                                label={`Twitter`}
                                size='small'
                                icon={<Twitter />}
                            />
                            {sideData.get() && sideData.get().links && sideData.get().links.twitter_screen_name ? <AddressRender router={router} address={`https://twitter.com/${sideData.get().links.twitter_screen_name}`} addlen={5} link={`https://twitter.com/${sideData.get().links.twitter_screen_name}`} ext={true} /> : <Typography fontSize={12}>NA</Typography>}
                        </Box>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", pt: 0.5 }}>
                            <Chip
                                label={`Facebook`}
                                size='small'
                                icon={<Facebook />}
                            />
                            {sideData.get() && sideData.get().links && sideData.get().links.facebook_username ? <AddressRender router={router} address={`https://www.facebook.com/${sideData.get().links.facebook_username}`} addlen={5} link={`https://www.facebook.com/${sideData.get().links.facebook_username}`} ext={true} /> : <Typography fontSize={12}>NA</Typography>}
                        </Box>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", pt: 0.5 }}>
                            <Chip
                                label={`Telegram`}
                                size='small'
                                icon={<Telegram />}
                            />
                            {sideData.get() && sideData.get().links && sideData.get().links.telegram_channel_identifier ? <AddressRender router={router} address={`https://t.me/${sideData.get().links.telegram_channel_identifier}`} addlen={5} link={`https://t.me/${sideData.get().links.telegram_channel_identifier}`} ext={true} /> : <Typography fontSize={12}>NA</Typography>}
                        </Box>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", pt: 0.5 }}>
                            <Chip
                                label={`Reddit`}
                                size='small'
                                icon={<Reddit />}
                            />
                            {sideData.get() && sideData.get().links && sideData.get().links.subreddit_url ? <AddressRender router={router} address={sideData.get().links.subreddit_url} addlen={5} link={sideData.get().links.subreddit_url} ext={true} /> : <Typography fontSize={12}>NA</Typography>}
                        </Box>
                    </Box>
                </SideContentCard>
            </Box>
        )
    }

    const DexInfoContent = () => {
        return (
            <Box sx={{ width: "100%", height: "100%" }}>
                <SideContentCard title={stringShort(sideData.get() && sideData.get().name ? sideData.get().name : 'NA', 0, 15)} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", height: "100%", width: "100%", justifyContent: "space-between" }}>
                        <Chip
                            label={"DEX CA"}
                            size='small'
                        />
                        <AddressRender router={router} address={sideData.get() && sideData.get().router ? sideData.get().router : 'NA'} addlen={8} link={sideData.get() && sideData.get().router ? `${chainDetails.token_link}${sideData.get().router}` : ''} ext={true} />
                    </Box>
                </SideContentCard>
                <SideContentCard title={'Factory'} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", height: "100%", width: "100%", justifyContent: "space-between" }}>
                        <Chip
                            label={"Factory CA"}
                            size='small'
                        />
                        <AddressRender router={router} address={sideData.get() && sideData.get().factory ? sideData.get().factory : 'NA'} addlen={8} link={sideData.get() && sideData.get().factory ? `${chainDetails.token_link}${sideData.get().factory}` : ''} ext={true} />
                    </Box>
                </SideContentCard>
                <SideContentCard title={"Router 24H Volume"} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <RenderPrice value={sideData.get() && sideData.get().t24h ? sideData.get().t24h.usdVolume : 0} pdecimal={4} usdtype={true} pfontsize={14} theme={theme} stringLen={6} />
                            <Chip
                                sx={{ ml: 0.5 }}
                                label={`${numShort(sideData.get() && sideData.get().t24h ? sideData.get().t24h.usdVolume : 0)} USD`}
                                size='small'
                            />
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <RenderPrice value={sideData.get() && sideData.get().t24h ? sideData.get().t24h.coinVolume : 0} pfontsize={14} theme={theme} stringLen={6} />
                            <Chip
                                sx={{ ml: 0.5 }}
                                label={`${numShort(sideData.get() && sideData.get().t24h ? sideData.get().t24h.coinVolume : 0)} ${stringShort(sideData.get() && sideData.get().chain && sideData.get().chain[0] ? sideData.get().chain[0].symbol.toUpperCase() : chainName.get().toUpperCase())}`}
                                size='small'
                            />
                        </Box>
                    </Box>
                </SideContentCard>
                <SideContentCard title={"Router 24H Transactions"} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <Chip
                            label={`Tx Count`}
                            size='small'
                        />
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <RenderPrice value={sideData.get() && sideData.get().t24h ? sideData.get().t24h.txCount : 0} pfontsize={14} theme={theme} stringLen={20} />
                            <Chip
                                sx={{ ml: 0.5 }}
                                label={`${numShort(sideData.get() && sideData.get().t24h ? sideData.get().t24h.txCount : 0)}`}
                                size='small'
                            />
                        </Box>
                    </Box>
                </SideContentCard>
            </Box>
        )
    }

    const PairInfoContent = () => {
        return (
            <Box sx={{ width: "100%", height: "100%" }}>
                <SideContentCard title={'Pair Price'} theme={theme} bottom={0.5} height="59px" >
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <RenderPrice value={sideData.get() && sideData.get().usdPrice ? sideData.get().usdPrice : 0} pdecimal={4} usdtype={true} pfontsize={14} theme={theme} stringLen={10} />
                            <Chip
                                sx={{ ml: 0.5 }}
                                label={"USD"}
                                size='small'
                            />
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <RenderPrice value={sideData.get() && sideData.get().coinPrice ? sideData.get().coinPrice : 0} pfontsize={14} theme={theme} stringLen={10} />
                            <Chip
                                sx={{ ml: 0.5 }}
                                label={chainDetails.symbol.toUpperCase()}
                                size='small'
                            />
                        </Box>
                    </Box>
                </SideContentCard>
                <SideContentCard title={'Token Market Capital'} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <RenderPrice value={sideData.get() && sideData.get().capUsd ? sideData.get().capUsd : 0} pdecimal={2} usdtype={true} pfontsize={12} theme={theme} stringLen={6} />
                            <Chip
                                sx={{ ml: 0.5 }}
                                label={`${numShort(sideData.get() && sideData.get().capUsd ? sideData.get().capUsd : 0, 2)} USD`}
                                size='small'
                            />
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <RenderPrice value={sideData.get() && sideData.get().capCoin ? sideData.get().capCoin : 0} pfontsize={12} theme={theme} stringLen={6} />
                            <Chip
                                sx={{ ml: 0.5 }}
                                label={`${numShort(sideData.get() && sideData.get().capCoin ? sideData.get().capCoin : 0, 2)} ${chainDetails.symbol.toUpperCase()}`}
                                size='small'
                            />
                        </Box>
                    </Box>
                </SideContentCard>
                <SideContentCard title={'Pair Liquidity Pool'} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <RenderPrice value={sideData.get() && sideData.get().reserve ? Decimal(Decimal(sideData.get().reserve.reserve0).div(`1e${sideData.get() && sideData.get().token0 ? sideData.get().token0.decimals : 0}`)).toString() : 0} pdecimal={2} pfontsize={12} theme={theme} stringLen={6} />
                            <Chip
                                sx={{ ml: 0.5 }}
                                label={`${numShort(sideData.get() && sideData.get().reserve ? Decimal(Decimal(sideData.get().reserve.reserve0).div(`1e${sideData.get() && sideData.get().token0 ? sideData.get().token0.decimals : 0}`)).toString() : 0, 2)} ${stringShort(sideData.get() && sideData.get().token0 ? sideData.get().token0.symbol : "NA", 0, 4)}`}
                                size='small'
                            />
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <RenderPrice value={sideData.get() && sideData.get().reserve ? Decimal(Decimal(sideData.get().reserve.reserve1).div(`1e${sideData.get() && sideData.get().token1 ? sideData.get().token1.decimals : 0}`)).toString() : 0} pdecimal={2} pfontsize={12} theme={theme} stringLen={6} />
                            <Chip
                                sx={{ ml: 0.5 }}
                                label={`${numShort(sideData.get() && sideData.get().reserve ? Decimal(Decimal(sideData.get().reserve.reserve1).div(`1e${sideData.get() && sideData.get().token1 ? sideData.get().token1.decimals : 0}`)).toString() : 0, 2)} ${stringShort(sideData.get() && sideData.get().token1 ? sideData.get().token1.symbol : "NA", 0, 4)}`}
                                size='small'
                            />
                        </Box>
                    </Box>
                </SideContentCard>
                <SideContentCard title={'Pair Change & Tx Count'} height={200} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", width: "100%", height: "100%", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}>
                            <Chip
                                sx={{ mt: 0.5 }}
                                label="Interval"
                                size='small'
                            />
                            <Chip
                                sx={{ mt: 0.5 }}
                                label="Change"
                                size='small'
                            />
                            <Chip
                                sx={{ mt: 0.5 }}
                                label="Volume"
                                size='small'
                            />
                            <Chip
                                sx={{ mt: 0.5 }}
                                label="Buy Count"
                                size='small'
                            />
                            <Chip
                                sx={{ mt: 0.5 }}
                                label="Sell Count"
                                size='small'
                            />
                            <Chip
                                sx={{ mt: 0.5 }}
                                label="Tx Count"
                                size='small'
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", justifyContent: "space-between", pb: 0.5 }}>
                            <Chip
                                sx={{ mt: 0.5 }}
                                label="15M"
                                size='small'
                            />
                            <Box sx={{ mt: 0.5 }}>
                                <RenderChange ovalue={sideData.get() && sideData.get().price ? sideData.get().price.t15 : 0} nvalue={sideData.get() && sideData.get().newPrice ? sideData.get().newPrice : 0} pfontsize={12} />
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>${numShort(sideData.get() && sideData.get().t15 ? sideData.get().t15.usdVolume : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t15 ? sideData.get().t15.txBuyCount : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t15 ? sideData.get().t15.txSellCount : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t15 ? sideData.get().t15.txCount : 0)}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", justifyContent: "space-between", pb: 0.5 }}>
                            <Chip
                                sx={{ mt: 0.5 }}
                                label="1H"
                                size='small'
                            />
                            <Box sx={{ mt: 0.5 }}>
                                <RenderChange ovalue={sideData.get() && sideData.get().price ? sideData.get().price.t1h : 0} nvalue={sideData.get() && sideData.get().newPrice ? sideData.get().newPrice : 0} pfontsize={12} />
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>${numShort(sideData.get() && sideData.get().t1h ? sideData.get().t1h.usdVolume : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t1h ? sideData.get().t1h.txBuyCount : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t1h ? sideData.get().t1h.txSellCount : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t1h ? sideData.get().t1h.txCount : 0)}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", justifyContent: "space-between", pb: 0.5 }}>
                            <Chip
                                sx={{ mt: 0.5 }}
                                label="6H"
                                size='small'
                            />
                            <Box sx={{ mt: 0.5 }}>
                                <RenderChange ovalue={sideData.get() && sideData.get().price ? sideData.get().price.t6h : 0} nvalue={sideData.get() && sideData.get().newPrice ? sideData.get().newPrice : 0} pfontsize={12} />
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>${numShort(sideData.get() && sideData.get().t6h ? sideData.get().t6h.usdVolume : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t6h ? sideData.get().t6h.txBuyCount : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t6h ? sideData.get().t6h.txSellCount : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t6h ? sideData.get().t6h.txCount : 0)}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", justifyContent: "space-between", pb: 0.5 }}>
                            <Chip
                                sx={{ mt: 0.5 }}
                                label="24H"
                                size='small'
                            />
                            <Box sx={{ mt: 0.5 }}>
                                <RenderChange ovalue={sideData.get() && sideData.get().price ? sideData.get().price.t24h : 0} nvalue={sideData.get() && sideData.get().newPrice ? sideData.get().newPrice : 0} pfontsize={12} />
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>${numShort(sideData.get() && sideData.get().t24h ? sideData.get().t24h.usdVolume : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t24h ? sideData.get().t24h.txBuyCount : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t24h ? sideData.get().t24h.txSellCount : 0)}</Typography>
                            </Box>
                            <Box sx={{ mt: 0.5 }}>
                                <Typography fontSize={12}>{numShort(sideData.get() && sideData.get().t24h ? sideData.get().t24h.txCount : 0)}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </SideContentCard>
                <SideContentCard title={sideData.get() && sideData.get().name ? sideData.get().name : ''} theme={theme} bottom={0.5} >
                    <Box sx={{ display: "flex", height: "100%", width: "100%", justifyContent: "space-between" }}>
                        <Chip
                            label={"Pair CA"}
                            size='small'
                        />
                        <AddressRender router={router} address={sideData.get() && sideData.get().pair ? sideData.get().pair : ''} addlen={10} link={sideData.get() && sideData.get().pair ? `${chainDetails.add_link}${sideData.get().pair}` : ''} ext={true} />
                    </Box>
                </SideContentCard>
                {/* <SideContentCard title={'Security Links'} theme={theme} bottom={0.5} >

                </SideContentCard> */}
                <SideContentCard title={'Token Links'} theme={theme} height={85}>
                    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", height: "100%", width: "100%", justifyContent: "space-between" }}>
                            <Chip
                                label={
                                    <Tooltip title={sideData.get() && sideData.get().token0 ? sideData.get().token0.symbol : 'NA'} placement='top' >
                                        <Typography fontSize={12}>{stringShort(sideData.get() && sideData.get().token0 ? sideData.get().token0.symbol : 'NA')}</Typography>
                                    </Tooltip>
                                }
                                size='small'
                                icon={
                                    sideData.get() && sideData.get().token0 && sideData.get().token0.image ?
                                        <Box sx={{ width: "16px", height: "16px", position: "relative", borderRadius: 1, overflow: "hidden" }}>
                                            <Image loader={myLoader} unoptimized={true} src={sideData.get().token0.image} alt={sideData.get() && sideData.get().token0 ? sideData.get().token0.symbol : 'NA'} layout="fill" objectFit={'contain'} />
                                        </Box>
                                        :
                                        <Avatar style={{ width: "16px", height: "16px", fontSize: "16px", fontWeight: "500" }} {...stringAvatar(sideData.get() && sideData.get().token0 ? sideData.get().token0.symbol : 'NA')}></Avatar>
                                }
                            />
                            <AddressRender router={router} address={sideData.get() && sideData.get().token0 ? sideData.get().token0.address : 'NA'} addlen={10} link={sideData.get() && sideData.get().token0 ? `/${chainDetails.path}/token/${sideData.get().token0.address}` : '/'} pageValue={'token'} chainValue={chainDetails.path} />
                        </Box>
                        <Box sx={{ mt: 0.5, display: "flex", height: "100%", width: "100%", justifyContent: "space-between" }}>
                            <Chip
                                label={
                                    <Tooltip title={sideData.get() && sideData.get().token1 ? sideData.get().token1.symbol : 'NA'} placement='top' >
                                        <Typography fontSize={12}>{stringShort(sideData.get() && sideData.get().token1 ? sideData.get().token1.symbol : 'NA')}</Typography>
                                    </Tooltip>
                                }
                                size='small'
                                icon={
                                    sideData.get() && sideData.get().token1 && sideData.get().token1.image ?
                                        <Box sx={{ width: "16px", height: "16px", position: "relative", borderRadius: 1, overflow: "hidden" }}>
                                            <Image loader={myLoader} unoptimized={true} src={sideData.get().token1.image} alt={sideData.get() && sideData.get().token1 ? sideData.get().token1.symbol : 'NA'} layout="fill" objectFit={'contain'} />
                                        </Box>
                                        :
                                        <Avatar style={{ width: "16px", height: "16px", fontSize: "16px", fontWeight: "500" }} {...stringAvatar(sideData.get() && sideData.get().token1 ? sideData.get().token1.symbol : 'NA')}></Avatar>
                                }
                            />
                            <AddressRender router={router} address={sideData.get() && sideData.get().token1 ? sideData.get().token1.address : 'NA'} addlen={10} link={sideData.get() && sideData.get().token1 ? `/${chainDetails.path}/token/${sideData.get().token1.address}` : '/'} pageValue={'token'} chainValue={chainDetails.path} />
                        </Box>
                    </Box>
                </SideContentCard>
            </Box>
        )
    }

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: "100%", height: "60px", pb: 0.5 }}>
                <Paper sx={{ p: 0.5, width: "100%", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                    <Box sx={{ width: "32px", height: "32px", position: "relative", borderRadius: 1, overflow: "hidden", ml: 0.5 }}>
                        {
                            chainName.get() == 'all' ?
                                <BlockChain fontSize='large' />
                                :
                                <Image loader={myLoader} unoptimized={true} src={chainDetails.image} alt="Icon" layout="fill" objectFit={'contain'} priority={true} />
                        }
                    </Box>
                    <Box sx={{ width: `calc(100% - 40px)`, height: "100%", display: "flex", flexDirection: "column", pl: 0.5, pr: 0.5 }}>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <Box sx={{ height: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                <Typography sx={{ whiteSpace: "nowrap", fontSize: 16 }}> {`${chainDetails.name.toUpperCase()} (${chainDetails.symbol.toUpperCase()})`} </Typography>
                            </Box>
                        </Box>
                        {
                            chainName.get() == 'all' ?
                                <Typography sx={{ whiteSpace: "nowrap", fontSize: 12, pb: 0.5 }}> Select a Network from below list </Typography>
                                :
                                <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box sx={{ height: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                        <RenderPrice value={sideData.get() && sideData.get().chain && sideData.get().chain[0] ? sideData.get().chain[0].coinUSD : 0} pdecimal={4} usdtype={true} pfontsize={14} theme={theme} tool={false} />
                                    </Box>
                                    <Box sx={{ height: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
                                        <Cube fontSize='10' />
                                        <RenderPrice value={sideData.get() && sideData.get().chain && sideData.get().chain[0] ? sideData.get().chain[0].blockNumber : 0} pdecimal={0} usdtype={false} pfontsize={14} theme={theme} tool={false} />
                                    </Box>
                                </Box>
                        }
                    </Box>
                </Paper>
            </Box>
            <Box sx={{ height: `calc(100% - 60px)`, width: "100%" }}>
                {
                    (pageName.get() == "pair" || pageName.get() == "dex" || pageName.get() == "token") ?
                        <Box sx={{ width: "100%", height: selAcc.get() === 1 ? `calc(100% - 84px)` : "42px", pb: 0.5 }} >
                            <Paper sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", pr: 0.5, pl: 0.5 }} >
                                <Box sx={{ width: "100%", height: "38px", display: "flex", alignItems: "center", cursor: "pointer", pl: 0.5 }} onClick={() => {
                                    selAcc.set(selAcc.get() != 1 ? 1 : 2)
                                }}>
                                    <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "start" }}>
                                        {pageName.get() == "pair" ? <JoinRight /> : pageName.get() == "token" ? <Token /> : pageName.get() == "dex" ? <AccountBalance /> : <BlockChain />}
                                        <Typography sx={{ pl: 1, display: "flex", alignItems: "center" }}>
                                            {pageName.get() == "pair" ? "Pair" : pageName.get() == "token" ? "Token" : pageName.get() == "dex" ? "DEX" : "Chain"} Info
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "end" }}>
                                        {
                                            selAcc.get() === 1 ?
                                                <ExpandLess />
                                                :
                                                <ExpandMore />
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{ display: selAcc.get() === 1 ? "block" : "none", width: "100%", height: selAcc.get() === 1 ? `calc(100% - 42px)` : 0 }}>
                                    {selAcc.get() === 1 ?
                                        <Paper elevation={0} sx={{ width: "100%", height: "100%", p: 0.5, overflow: "auto", background: theme.palette.background.default }}>
                                            <Box sx={{ width: "100%", height: "100%" }}>
                                                {
                                                    pageName.get() == "pair" ?
                                                        <PairInfoContent />
                                                        : pageName.get() == "token" ?
                                                            <TokenInfoContent />
                                                            : pageName.get() == "dex" ?
                                                                <DexInfoContent />
                                                                :
                                                                <Paper elevation={0} sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", overflow: "auto"/* , background: theme.palette.background.default */ }}>

                                                                </Paper>
                                                }
                                            </Box>
                                        </Paper>
                                        : <></>}
                                </Box>
                            </Paper>
                        </Box>
                        : <></>
                }
                <Box sx={{ width: "100%", height: selAcc.get() === 2 ? `calc(100% - ${pageName.get() == "pair" || pageName.get() == "dex" || pageName.get() == "token" ? 84 : 42}px)` : "42px", pb: 0.5 }} >
                    <Paper sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", pr: 0.5, pl: 0.5 }} >
                        <Box sx={{ width: "100%", height: "38px", display: "flex", alignItems: "center", cursor: "pointer", pl: 0.5 }} onClick={() => {
                            selAcc.set(selAcc.get() != 2 ? 2 : pageName.get() == "pair" || pageName.get() == "dex" || pageName.get() == "token" ? 1 : 3)
                        }}>
                            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "start" }}>
                                <Network />
                                <Typography sx={{ pl: 1, display: "flex", alignItems: "center" }}>
                                    Select Network
                                </Typography>
                            </Box>
                            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "end" }}>
                                {
                                    selAcc.get() === 2 ?
                                        <ExpandLess />
                                        :
                                        <ExpandMore />
                                }
                            </Box>
                        </Box>
                        <Box sx={{ display: selAcc.get() === 2 ? "block" : "none", width: "100%", height: selAcc.get() === 2 ? `calc(100% - 42px)` : 0 }}>
                            {selAcc.get() === 2 ?
                                <Paper elevation={0} sx={{ width: "100%", height: "100%", overflow: "auto"/* , background: theme.palette.background.default */ }}>
                                    <NetworkListRender />
                                </Paper> : <></>}
                        </Box>
                    </Paper>
                </Box>

                <Box sx={{ width: "100%", height: selAcc.get() === 3 ? `calc(100% - ${pageName.get() == "pair" || pageName.get() == "dex" || pageName.get() == "token" ? 84 : 42}px)` : "42px" }} >
                    <Paper sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", pr: 0.5, pl: 0.5 }} >
                        <Box sx={{ width: "100%", height: "38px", display: "flex", alignItems: "center", cursor: "pointer", pt: selAcc.get() != 3 ? 0.5 : 0, pl: 0.5 }} onClick={() => {
                            selAcc.set(selAcc.get() != 3 ? 3 : pageName.get() == "pair" || pageName.get() == "dex" || pageName.get() == "token" ? 1 : 2)
                        }}>
                            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "start" }}>
                                <Favorite />
                                <Typography sx={{ pl: 1, display: "flex", alignItems: "center" }}>
                                    Favorites
                                </Typography>
                            </Box>
                            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "end" }}>
                                {
                                    selAcc.get() === 3 ?
                                        <ExpandLess />
                                        :
                                        <ExpandMore />
                                }
                            </Box>
                        </Box>
                        <Box sx={{ display: selAcc.get() === 3 ? "block" : "none", width: "100%", height: selAcc.get() === 3 ? `calc(100% - 42px)` : 0 }}>
                            {selAcc.get() === 3 ?
                                wallet.get().connected ?
                                    Object.values(favList.get({ noproxy: true })).length > 0 ?
                                        <Paper elevation={0} sx={{ width: "100%", height: "100%", background: theme.palette.background.default }}>
                                            <PairTable theme={theme} router={router} favList={favList} alerting={alerting} />
                                        </Paper>
                                        :
                                        <Paper elevation={0} sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", overflow: "auto", p: 1, background: theme.palette.background.default }}>
                                            Add your favourite pair from search or pair page.
                                        </Paper>
                                    :
                                    <Paper elevation={0} sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", overflow: "auto", p: 1, background: theme.palette.background.default }}>
                                        Connect wallet to use this feature.
                                    </Paper>
                                :
                                <></>
                            }
                            {/* <Paper elevation={0} sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", overflow: "auto" }}>
                                Coming Soon
                            </Paper> */}
                        </Box>
                    </Paper>
                </Box>

            </Box >
        </Box >
    )

}