import {
    Box,
    Tooltip,
    Typography
} from '@mui/material';
import { stringShort } from '@/libs/fncs';
import { ContentCopy, OpenInNew } from '@mui/icons-material';
import Clink from './Clink';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { alertd } from '@/libs/store';
import { useHookstate } from '@hookstate/core';

export default function AddressRender({ address, addlen, link, ext, pageValue, chainValue, router }) {

    const alert = useHookstate(alertd);

    return (
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <CopyToClipboard text={address ? address : ''} onCopy={() => { alert.alertStatus.set(true); alert.alertMessage.set("Copied"); alert.alertType.set("success"); alert.alertDuration.set(5000) }}>
                <Tooltip title={address} placement="top" sx={{ overflow: "auto" }}>
                    <Box sx={{ display: "flex", alignItems: "center", height: "100%", cursor: "pointer" }}>
                        <Typography sx={{ fontSize: 14, textAlign: 'right', width: "100%", pl: 0.5 }}>
                            {stringShort(address, 1, addlen)}
                        </Typography>
                        <ContentCopy sx={{ fontSize: 20, pl: 0.5 }} />
                    </Box>
                </Tooltip>
            </CopyToClipboard>
            <Clink router={router} href={link} ext={ext} pageValue={pageValue} addValue={address} chainValue={chainValue}>
                <OpenInNew sx={{ fontSize: 20, pl: 0.5 }} />
            </Clink>
        </Box>
    )

}