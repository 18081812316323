import { numShort, stringShort } from '@/libs/fncs';
import {
    Box, Chip, CircularProgress, Divider, Paper, Typography
} from '@mui/material';
//import { DataGrid, gridClasses } from "@mui/x-data-grid";
//import axios from 'axios';
import useSWR, { preload } from 'swr';
import RenderDate from './RenderDate';
import RenderPrice from './RenderPrice';
import { apiUrl, historyDatad, networkList, sidedatad, swapsd, tableLoad, walletd } from '@/libs/store';
import { Visibility } from '@mui/icons-material';
import { Paid } from '@mui/icons-material';
import AddressRender from './AddressRender';
import { useHookstate } from '@hookstate/core';
import { useEffect } from 'react';
//import { useRouter } from "next/router";
const Decimal = require("decimal.js");

export default function PairHistory({ url, mounted, historyData, sideData, tableLoading, chainName, theme, seo, market, router, tvw, views, apidata, dataload }) {

    //const router = useRouter();
    const chainDetails = networkList[chainName.get({ noproxy: true })];
    //const historyData = useHookstate(apidata);
    //const sideData = useHookstate(sidedatad);
    //const tableLoading = useHookstate(tableLoad);
    //console.log(apidata);

    const fetcher = url => fetch(url).then(async (res) => {
        res = { data: await res.json() };
        try {
            var temp = res.data.data[0].result;

            //if (temp && temp[0]) {

            if (temp[0] && temp[0].usdPrice) {
                await Promise.allSettled(temp.map(async (row, i) => {
                    temp[i].id = i + 1;
                }))
            }
            var side = res.data.data[0].side;
            const coinpair = chainDetails.basePair[0];
            const usdpair = chainDetails.basePair.filter(item => item !== coinpair);
            var token = side.token0;
            var marketType = 'coin';
            if (usdpair.indexOf(side.token0.address.toLowerCase()) > -1 || usdpair.indexOf(side.token1.address.toLowerCase()) > -1) {
                if (usdpair.indexOf(side.token0.address.toLowerCase()) > -1) {
                    token = side.token1;
                }
                marketType = 'usd';
            } else if (coinpair.toLowerCase() == side.token0.address.toLowerCase()) {
                token = side.token1;
            }

            if (!market.get().updated) market.set({ type: marketType, updated: true });

            if (mounted.get() && side.pair != sideData.get().pair && tvw.get()) {
                //console.log(`${side.pair}-${chainName.get()}-${marketType}`);
                tvw.get().setSymbol(`${side.pair}-${chainName.get()}-${marketType}`, '15')
            };

            side.token = token;
            if (temp[0] && temp[0].usdPrice) {
                side.usdPrice = temp[0].usdPrice;
                side.coinPrice = temp[0].coinPrice;
                side.newPrice = temp[0].usdPrice;
                historyData.set(temp);
            } else {
                //historyData.set([]);
                side.newPrice = side.usdPrice;
            }

            sideData.set(side);

            sideData.capUsd.set(Decimal(Decimal(token && token.totalSupply ? token.totalSupply : 0).div(`1e${token && token.decimals ? token.decimals : 0}`)).mul(sideData.get() && sideData.get().usdPrice ? sideData.get().usdPrice : 0).toString());// = await Decimal(Decimal(token && token.totalSupply ? token.totalSupply : 0).div(`1e${token && token.decimals ? token.decimals : 0}`)).mul(sideData.get() && sideData.get().usdPrice ? sideData.get().usdPrice : 0).toString();
            sideData.capCoin.set(Decimal(Decimal(token && token.totalSupply ? token.totalSupply : 0).div(`1e${token && token.decimals ? token.decimals : 0}`)).mul(sideData.get() && sideData.get().coinPrice ? sideData.get().coinPrice : 0).toString());// = await Decimal(Decimal(token && token.totalSupply ? token.totalSupply : 0).div(`1e${token && token.decimals ? token.decimals : 0}`)).mul(sideData.get() && sideData.get().coinPrice ? sideData.get().coinPrice : 0).toString();
            seo['pair'].merge({ tabTitle: `$${side.newPrice.slice(0, 8)} ${side.token0.symbol.toUpperCase()}/${side.token1.symbol.toUpperCase()} (${chainDetails.path.toUpperCase()}) | ${stringShort(side.token.name, 0, 15)}` });

            tableLoading.set(false);
            /* } else {
                chainName.set("all");
                pageName.set('home');
                router.push(`/[chain]`, `/all`, { shallow: true });
            } */

            return res.data
        } catch (e) {
            //console.log(e);
        }
    });

    preload(url, fetcher);

    useSWR(dataload.get() && mounted.get() ? url : null, fetcher, { refreshInterval: 10000 });

    return (
        <Paper sx={{ width: "100%", height: "100%", overflow: 'auto' }}>
            <Paper elevation={2} sx={{ width: "100%", minWidth: "1340px", height: "32px", position: 'sticky', top: 0, zIndex: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "140px", maxWidth: "140px", display: "flex", alignItems: "center", pl: 1, fontSize: 12 }}>
                        Time
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "160px", display: "flex", alignItems: "center", pl: 1, pr: 1, fontSize: 12 }}>
                        Price USD
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "200px", display: "flex", alignItems: "center", pl: 1, pr: 1, fontSize: 12 }}>
                        {`Amount ${sideData.get().token ? stringShort(sideData.get().token.symbol.toUpperCase(), 0, 5) : chainDetails.symbol.toUpperCase()}`}
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "160px", display: "flex", alignItems: "center", pl: 1, pr: 1, fontSize: 12 }}>
                        {"Price " + chainDetails.symbol.toUpperCase()}
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "200px", display: "flex", alignItems: "center", pl: 1, pr: 1, fontSize: 12 }}>
                        Total USD
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "200px", maxWidth: "200px", display: "flex", alignItems: "center", pl: 1, pr: 1, fontSize: 12 }}>
                        Maker
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "140px", display: "flex", alignItems: "center", maxWidth: "140px", pl: 1, pr: 1, fontSize: 12 }}>
                        Router
                    </Typography>
                    <Typography sx={{ width: "100%", height: "100%", minWidth: "140px", display: "flex", alignItems: "center", maxWidth: "140px", pl: 1, pr: 1, fontSize: 12 }}>
                        Tx
                    </Typography>
                </Box>
            </Paper>
            <Box sx={{ width: "100%", height: `calc(100% - 32px)`, zIndex: 1 }}>
                {tableLoading.get() ?
                    <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                    :
                    !historyData.get()[0] ?
                        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", ml: 1 }}>
                            No Data Found
                        </Box>
                        :
                        (historyData.get()).map((row, i) => {
                            const totusd = Decimal(row.type == "buy" ? row.receiveAmt : row.sendAmt).mul(row.usdPrice).toString();
                            return (
                                <Box key={row.id} sx={{ width: "100%", minWidth: "1340px", height: "40px" }}>
                                    {
                                        row.id === 1 ? <></> : <Divider />
                                    }
                                    <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "140px", maxWidth: "140px", display: "flex", alignItems: "center", pl: 1 }}>
                                            <RenderDate value={row.timeStamp} />
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "160px", display: 'flex', alignItems: "center", pl: 1, pr: 1 }}>
                                            <RenderPrice value={row.usdPrice} pdecimal={6} usdtype={true} pfontsize={14} theme={theme} pcolor={row.type == "sell" ? "rgba(255, 99, 71, 1)" : "rgba(60, 179, 113, 1)"} />
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "200px", display: 'flex', alignItems: "center", pl: 1, pr: 1 }}>
                                            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
                                                <RenderPrice value={row.type == "buy" ? row.receiveAmt : row.sendAmt} usdtype={false} pfontsize={14} theme={theme} pcolor={row.type == "sell" ? "rgba(255, 99, 71, 1)" : "rgba(60, 179, 113, 1)"} />
                                                <Chip
                                                    sx={{ ml: 1 }}
                                                    label={<Typography fontSize={12}>{numShort(row.type == "buy" ? row.receiveAmt : row.sendAmt, 2)}</Typography>}
                                                    size='small'
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "160px", display: 'flex', alignItems: "center", pl: 1, pr: 1 }}>
                                            <RenderPrice value={row.coinPrice ? row.coinPrice : 0} usdtype={false} pfontsize={14} theme={theme} pcolor={row.type == "sell" ? "rgba(255, 99, 71, 1)" : "rgba(60, 179, 113, 1)"} />
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "200px", pl: 1, pr: 1 }}>
                                            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
                                                <RenderPrice value={totusd} usdtype={true} pfontsize={14} theme={theme} pcolor={row.type == "sell" ? "rgba(255, 99, 71, 1)" : "rgba(60, 179, 113, 1)"} />
                                                <Chip
                                                    sx={{ ml: 1 }}
                                                    label={<Typography fontSize={12}>{numShort(totusd, 2)}</Typography>}
                                                    size='small'
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "200px", maxWidth: "200px", pl: 1, pr: 1 }}>
                                            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
                                                <AddressRender router={router} address={row.maker} addlen={4} link={`${chainDetails.add_link}${row.maker}`} ext={true} />
                                                <Chip
                                                    sx={{ ml: 1 }}
                                                    label={<Typography fontSize={12}>{numShort(row.mkcount)}</Typography>}
                                                    size='small'
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "140px", maxWidth: "140px", pl: 1, pr: 1 }}>
                                            <AddressRender router={router} address={row.router ? row.router : ''} addlen={4} link={`${chainDetails.add_link}${row.router ? row.router : ''}`} ext={true} />
                                        </Box>
                                        <Box sx={{ width: "100%", height: "100%", minWidth: "140px", maxWidth: "140px", pl: 1, pr: 1 }}>
                                            <AddressRender router={router} address={row.transactionHash} addlen={4} link={`${chainDetails.tx_link}${row.transactionHash}`} ext={true} />
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })
                }
            </Box>
            {/* 
                tableLoading.get() ?
                    <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center', p: 1 }}>
                        <CircularProgress />
                    </Box>
                    :
                    <DataGrid
                        density="compact"
                        disableColumnMenu
                        getRowHeight={() => 'auto'}
                        isRowSelectable={() => false}
                        columns={[
                            {
                                field: 'timeStamp', headerName: 'Time', flex: 1, sortable: false,
                                renderCell: (props) => {
                                    return (
                                        <RenderDate value={props.row.timeStamp} />
                                    )
                                }
                                , minWidth: 140, maxWidth: 140,
                            },
                            {
                                field: 'usdPrice', headerName: "Price USD", flex: 1, sortable: false, renderCell: (props) => {
                                    return (
                                        <RenderPrice value={props.row.usdPrice} pdecimal={4} usdtype={true} pfontsize={14} theme={theme} pcolor={props.row.type == "sell" ? "rgba(255, 99, 71, 1)" : "rgba(60, 179, 113, 1)"} />
                                    )
                                }, minWidth: 160,
                            },
                            {
                                field: 'token', headerName: `Amount ${sideData.get().token ? stringShort(sideData.get().token.symbol.toUpperCase(), 0, 5) : chainDetails.symbol.toUpperCase()}`, flex: 1, sortable: false,
                                renderCell: (props) => {
                                    return (
                                        <RenderPrice value={props.row.type == "buy" ? props.row.receiveAmt : props.row.sendAmt} usdtype={false} pfontsize={14} theme={theme} pcolor={props.row.type == "sell" ? "rgba(255, 99, 71, 1)" : "rgba(60, 179, 113, 1)"} />
                                    )
                                }
                                , minWidth: 160
                            },
                            {
                                field: 'coinPrice', headerName: "Price " + chainDetails.symbol.toUpperCase(), flex: 1, sortable: false, renderCell: (props) => {
                                    return (
                                        <RenderPrice value={props.row.coinPrice ? props.row.coinPrice : 0} usdtype={false} pfontsize={14} theme={theme} pcolor={props.row.type == "sell" ? "rgba(255, 99, 71, 1)" : "rgba(60, 179, 113, 1)"} />
                                    )
                                }, minWidth: 160,
                            },
                            {
                                field: 'usd', headerName: 'Total USD', flex: 1, sortable: false, renderCell: (props) => {

                                    const totusd = Decimal(props.row.type == "buy" ? props.row.receiveAmt : props.row.sendAmt).mul(props.row.usdPrice).toString();
                                    return (
                                        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <RenderPrice value={totusd} usdtype={true} pfontsize={14} theme={theme} pcolor={props.row.type == "sell" ? "rgba(255, 99, 71, 1)" : "rgba(60, 179, 113, 1)"} />
                                            <Chip
                                                label={<Typography fontSize={12}>{numShort(totusd)}</Typography>}
                                                icon={<Paid />}
                                                size='small'
                                            />
                                        </Box>
                                    )
                                }, minWidth: 160
                            },
                            {
                                field: 'maker', headerName: 'Maker', flex: 1, sortable: false, renderCell: (props) => {
                                    return (
                                        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <AddressRender router={router} address={props.row.maker} addlen={4} link={`${chainDetails.add_link}${props.row.maker}`} ext={true} />
                                            <Chip
                                                label={<Typography fontSize={12}>{numShort(props.row.mkcount)}</Typography>}
                                                onClick={() => { }}
                                                size='small'
                                            />
                                        </Box>
                                    )
                                }, minWidth: 200, maxWidth: 200
                            },
                            {
                                field: 'router', headerName: 'Router', flex: 1, sortable: false, renderCell: (props) => {
                                    return (
                                        <AddressRender router={router} address={props.row.router ? props.row.router : ''} addlen={4} link={`${chainDetails.add_link}${props.row.router ? props.row.router : ''}`} ext={true} />
                                    )
                                }, maxWidth: 140, minWidth: 140
                            },
                            {
                                field: 'transactionHash', headerName: 'Tx', flex: 1, sortable: false, renderCell: (props) => {
                                    return (
                                        <AddressRender router={router} address={props.row.transactionHash} addlen={4} link={`${chainDetails.tx_link}${props.row.transactionHash}`} ext={true} />
                                    )
                                }, maxWidth: 140, minWidth: 140
                            },
                        ]}
                        rows={historyData.get()}
                        disableVirtualization
                        loading={tableLoading.get()}
                        hideFooter={true}
                        sx={{
                            boxShadow: 0,
                            border: 0,
                            [`& .${gridClasses.columnHeader}`]: {
                                //backgroundColor: theme.palette.background.default,
                            },
                            [`& .${gridClasses.cell}`]: {
                                //backgroundColor: theme.palette.background.paper,
                                py: 0.5,
                                //outline: 'none !important',
                            },
                        }}
                    /> */}
        </Paper>
    )

}
