
import {
    Box,
    Typography,
    Tooltip,
} from '@mui/material';
import { numFormat, stringShort } from '@/libs/fncs';
const Decimal = require("decimal.js");

function RenderPrice({ value, pdecimal, usdtype, pcolor, pfontsize, theme, stringLen, tipDisabled, tool = true }) {

    pdecimal = pdecimal || pdecimal === 0 ? pdecimal : 2;
    try {
        //console.log(result);
        value = Number(value && value != "NaN" && value != undefined ? value : "0").toFixed(18);
        var precision = -Math.floor(Math.log10(Number(value)) + 1);
        precision = precision != Infinity ? precision : 0;
        //console.log(value, precision, precision != Infinity);
        var result = 0;
        var splited = value.toString().split(".");
        var format = false;
        //console.log(splited)
        if (precision > 3) {
            format = true;
            result = splited[1].slice(precision);
            result = result.substring(0, result.length > 4 ? 4 : result.length);
            //console.log("2", result);
            //result = ("0.".concat(result)).toString().split(".")[1];
            /* var splited = value.toString().split(".");
            result = `${splited[0]}.${}` */
        } else {
            precision = precision > 0 ? precision + pdecimal : pdecimal;
            result = Number(Number(value).toFixed(precision));
        }

        return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                {
                    tool ?
                        <Tooltip title={numFormat(value)} aria-disabled={tipDisabled} placement="top" sx={{ overflow: "auto" }}>
                            {
                                format ?
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography sx={pcolor ? { color: pcolor, fontSize: pfontsize ? pfontsize : 16 } : { fontSize: pfontsize ? pfontsize : 16 }}>
                                            {`${usdtype ? "$" : ""}${numFormat(splited[0])}.0`}
                                        </Typography>
                                        <Typography sx={pcolor ? { color: pcolor, fontSize: 14, pt: 1 } : { color: theme.palette.primary.main, fontSize: 14, pt: 1 }}>
                                            {precision && precision > 3 && precision != NaN ? parseInt(precision - 2) : 0}
                                        </Typography>
                                        <Typography sx={pcolor ? { color: pcolor, fontSize: pfontsize ? pfontsize : 16 } : { fontSize: pfontsize ? pfontsize : 16 }}>
                                            {"0".concat(result)}
                                        </Typography>
                                    </Box>
                                    :
                                    <Typography sx={{ color: pcolor, fontSize: pfontsize ? pfontsize : 16 }}>
                                        {stringShort(`${usdtype ? "$" : ""}${numFormat(parseInt(splited[0]))}${pdecimal > 0 ? '.' : ''}${pdecimal == 0 ? '' : splited[1].substring(0, parseInt(splited[0]) == 0 ? pdecimal > 2 ? pdecimal : 6 : splited[0].length > 2 ? 2 : pdecimal)}`, 0, stringLen ? stringLen : 15)}
                                    </Typography>
                            }
                        </Tooltip>
                        :
                        format ?
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={pcolor ? { color: pcolor, fontSize: pfontsize ? pfontsize : 16 } : { fontSize: pfontsize ? pfontsize : 16 }}>
                                    {`${usdtype ? "$" : ""}${numFormat(splited[0])}.0`}
                                </Typography>
                                <Typography sx={pcolor ? { color: pcolor, fontSize: 14, pt: 1 } : { color: theme.palette.primary.main, fontSize: 14, pt: 1 }}>
                                    {precision && precision > 3 && precision != NaN ? parseInt(precision - 2) : 0}
                                </Typography>
                                <Typography sx={pcolor ? { color: pcolor, fontSize: pfontsize ? pfontsize : 16 } : { fontSize: pfontsize ? pfontsize : 16 }}>
                                    {"0".concat(result)}
                                </Typography>
                            </Box>
                            :
                            <Typography sx={{ color: pcolor, fontSize: pfontsize ? pfontsize : 16 }}>
                                {stringShort(`${usdtype ? "$" : ""}${numFormat(parseInt(splited[0]))}${pdecimal > 0 ? '.' : ''}${pdecimal == 0 ? '' : splited[1].substring(0, parseInt(splited[0]) == 0 ? pdecimal > 2 ? pdecimal : 6 : splited[0].length > 2 ? 2 : pdecimal)}`, 0, stringLen ? stringLen : 15)}
                            </Typography>
                }
            </Box>
        )
    } catch (e) {
        return (
            <Typography sx={{ color: pcolor, fontSize: pfontsize ? pfontsize : 16 }}>
                {usdtype ? "$" : ""}0.00
            </Typography>
        )
    }
}

export default RenderPrice;