
import {
    Box,
    Paper,
    Typography,
    ButtonGroup,
    Button,
    Chip,
    IconButton,
} from '@mui/material';
import { Handshake, SwapVert, Token } from '@mui/icons-material';
import { Article } from '@mui/icons-material';
import { Shield } from '@mui/icons-material';
import { Gavel } from '@mui/icons-material';
import { SmartToy } from '@mui/icons-material';
import { DonutLarge } from '@mui/icons-material';
import { FiberNew } from '@mui/icons-material';
import { FindInPage } from '@mui/icons-material';
import { TravelExplore } from '@mui/icons-material';
import { TrendingUp } from '@mui/icons-material';
import { TrendingDown } from '@mui/icons-material';
import { CandlestickChart } from '@mui/icons-material';
import { SwapHoriz } from '@mui/icons-material';
import RenderLogo from './RenderLogo';
import { favs, stringShort } from '@/libs/fncs';
import { Favorite } from '@mui/icons-material';
import { Star } from '@mui/icons-material';
import { JoinRight } from '@mui/icons-material';
import { AccountBalance } from '@mui/icons-material';
import { createSvgIcon } from '@mui/material/utils';

const ClosePane = createSvgIcon(
    <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 14 5 L 14 12 L 14 19 L 5 19 L 5 5 z M 14 12 L 10 8 L 10 11 L 7 11 L 7 13 L 10 13 L 10 16 L 14 12 z M 16 5 L 19 5 L 19 19 L 16 19 L 16 5 z" />,
    'ClosePane',
);
const OpenPane = createSvgIcon(
    <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 14 5 L 14 11 L 11 11 L 11 8 L 7 12 L 11 16 L 11 13 L 14 13 L 14 19 L 5 19 L 5 5 z M 16 5 L 19 5 L 19 19 L 16 19 L 16 5 z" />,
    'OpenPane',
);
/* <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M16,17H8v-2h8V17z M19,19H5V5h14V19z" />, */
const NormalView = createSvgIcon(
    <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 13 L 5 13 L 5 5 z M 5 15 L 19 15 L 19 19 L 5 19 L 5 15 z M 12 15.75 L 10.75 17 L 12 18.25 L 13.25 17 L 12 15.75 z M 8 16 A 1 1 0 0 0 7 17 A 1 1 0 0 0 8 18 A 1 1 0 0 0 9 17 A 1 1 0 0 0 8 16 z M 15 16 L 15 18 L 17 18 L 17 16 L 15 16 z" />,
    'NormalView',
);


export default function HeaderTitle({ pageTitle, pagetype, views, widths, gainer, market, chain, chainName, networkList, address, tvw, sideData, favList, alerting, wallet }) {

    return (
        <Paper sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "100%", pl: 1, pr: 1, overflow: "hidden" }}>

            <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
                <Chip
                    label={
                        pagetype == "pair" ?
                            <Typography variant='h1' fontSize={18} fontWeight={"bold"} sx={{ whiteSpace: "nowrap" }}>
                                {`${stringShort(sideData.get() && sideData.get().token0 ? sideData.get().token0.symbol : "NA", 0, widths.get() < 900 ? 3 : 5)} / ${stringShort(sideData.get() && sideData.get().token1 ? sideData.get().token1.symbol : "NA", 0, widths.get() < 900 ? 3 : 5)}`}
                            </Typography>
                            :
                            pagetype == "dex" ?
                                <Typography variant='h1' fontSize={18} fontWeight={"bold"} sx={{ whiteSpace: "nowrap" }}>
                                    {`${sideData.get() && sideData.get().name ? stringShort(sideData.get().name, 0, 5) : "DEX"} Pair List ${chain.toUpperCase()} Chain`}
                                </Typography>
                                :
                                pagetype == "token" ?
                                    <Typography variant='h1' fontSize={18} fontWeight={"bold"} sx={{ whiteSpace: "nowrap" }}>
                                        {`${sideData.get() && sideData.get().name ? stringShort(sideData.get().name, 0, 5) : "Token"} Pair List ${chain.toUpperCase()} Chain`}
                                    </Typography>
                                    :
                                    <Typography variant='h1' fontSize={18} fontWeight={"bold"} sx={{ whiteSpace: "nowrap" }}> {widths.get() < 900 ? pageTitle : `${pageTitle} ${pagetype == 'bot' || pagetype == 'terms' || pagetype == 'privacy' || pagetype == 'partners' ? '' : `${chain.toUpperCase()} Chain`}`}
                                    </Typography>
                    }
                    onClick={() => {
                        if (pagetype == "topgainers" && !gainer.get()) {
                            gainer.set(true)
                        } /*else if (pagetype == "pair") {
                            if (wallet.get().connected) {
                                favs({ address: sideData.get() && sideData.get().pair ? sideData.get().pair : "", path: sideData.get() && sideData.get().chain ? sideData.get().chain[0].symbol : "all" }, favList, alerting, true);
                            } else {
                                alerting.merge({
                                    status: true,
                                    duration: 5000,
                                    type: "error",
                                    message: "Connect wallet to use this feature.",
                                })
                            }
                        }*/

                    }}
                    icon={
                        pagetype == "terms" ? <Gavel />
                            : pagetype == "privacy" ? <Shield />
                                : pagetype == "partners" ? <Handshake />
                                    : pagetype == "bot" ? <SmartToy />
                                        : pagetype == "dexlist" ? <AccountBalance />
                                            : pagetype == "pairlist" ? <JoinRight />
                                                : pagetype == "newpair" ? <FiberNew />
                                                    : pagetype == "bigswap" ? <DonutLarge />
                                                        : pagetype == "topgainers" ? <TrendingUp />
                                                            : pagetype == "dex" ? <AccountBalance />
                                                                : pagetype == "pair" ?
                                                                    <Box sx={{ height: "100%", display: "flex", pl: 0.5, alignItems: "center", width: widths.get() < 900 ? "48px" : "52px", justifyContent: 'space-between' }}>
                                                                        <Box sx={{ display: "flex", alignItems: "center", position: 'relative', width: widths.get() < 900 ? "18px" : "20px", height: widths.get() < 900 ? "18px" : "20px" }}>
                                                                            <RenderLogo image={sideData.get() && sideData.get().token0 ? sideData.get().token0.image : null} value={sideData.get() && sideData.get().token0 ? sideData.get().token0.symbol : "NA"} width={widths.get() < 900 ? "18px" : "20px"} height={widths.get() < 900 ? "18px" : "20px"} />
                                                                        </Box>
                                                                        <Box sx={{ display: "flex", alignItems: "center", mr: 0.5, pl: 0.5, position: 'relative', width: widths.get() < 900 ? "18px" : "20px", height: widths.get() < 900 ? "18px" : "20px" }}>
                                                                            <RenderLogo image={sideData.get() && sideData.get().token1 ? sideData.get().token1.image : null} value={sideData.get() && sideData.get().token1 ? sideData.get().token1.symbol : "NA"} width={widths.get() < 900 ? "18px" : "20px"} height={widths.get() < 900 ? "18px" : "20px"} />
                                                                        </Box>
                                                                    </Box>
                                                                    : <Token />
                    }
                    variant={pagetype != "topgainers" ? "filled" : gainer.get() ? "filled" : "outlined"}
                    /*color={pagetype != "topgainers" ? "primary" : gainer.get() ? "primary" : "default"}*/
                    size={widths.get() < 900 ? 'small' : 'medium'}
                />
                {
                    pagetype == "topgainers" ?
                        <Chip
                            sx={{ ml: 0.5 }}
                            label={
                                <Typography fontSize={18} fontWeight={"bold"} sx={{ whiteSpace: "nowrap" }}>
                                    Losers
                                </Typography>
                            }
                            onClick={() => { gainer.set(false) }}
                            icon={<TrendingDown />}
                            variant={gainer.get() ? "outlined" : "filled"}
                            /*color={gainer.get() ? "default" : "primary"}*/
                            size={widths.get() < 900 ? 'small' : 'medium'}
                        />
                        :
                        pagetype == "pair" ?
                            <Box sx={{ pl: 1, height: "100%", display: "flex", alignItems: "center" }}>
                                <Favorite sx={{ color: favList.get()[sideData.get() && sideData.get().pair ? sideData.get().pair : ""] ? 'red' : 'inherit', cursor: 'pointer' }} fontSize={widths.get() < 900 ? 'small' : 'medium'} onClick={() => {
                                    if (pagetype == "pair") {
                                        if (wallet.get().connected) {
                                            favs({ address: sideData.get() && sideData.get().pair ? sideData.get().pair : "", path: sideData.get() && sideData.get().chain ? sideData.get().chain[0].symbol : "all" }, favList, alerting, true);
                                        } else {
                                            alerting.merge({
                                                status: true,
                                                duration: 5000,
                                                type: "error",
                                                message: "Connect wallet to use this feature.",
                                            })
                                        }
                                    }
                                }} />
                                <ButtonGroup size='small' variant='outlined' sx={{ ml: 1 }}>
                                    <Button startIcon={<CandlestickChart />} onClick={() => {
                                        market.type.set(market.get() && market.get().type && market.get().type == 'usd' ? networkList.get()[chainName.get()].symbol.toUpperCase() : 'usd');
                                        tvw.get().setSymbol(`${address.get()}-${chainName.get()}-${market.get() && market.get().type ? market.get().type : 'usd'}`, '15');
                                    }}>
                                        {market.get() && market.get().type && market.get().type == 'usd' ? "USD" : networkList.get()[chainName.get()].symbol.toUpperCase()}
                                    </Button>
                                    <Button startIcon={<SwapHoriz />} onClick={() => {

                                        alerting.merge({
                                            status: true,
                                            duration: 5000,
                                            type: "info",
                                            message: "This feature is coming soon.",
                                        })
                                    }}>
                                        Swap
                                    </Button>
                                </ButtonGroup>
                                {/*<Chip
                                    sx={{ ml: 1 }}
                                    label={market.get() && market.get().type && market.get().type == 'usd' ? "USD" : networkList.get()[chainName.get()].symbol.toUpperCase()}
                                    onClick={() => {
                                        market.type.set(market.get() && market.get().type && market.get().type == 'usd' ? networkList.get()[chainName.get()].symbol.toUpperCase() : 'usd');
                                        tvw.get().setSymbol(`${address.get()}-${chainName.get()}-${market.get() && market.get().type ? market.get().type : 'usd'}`, '15');
                                    }}
                                    icon={<CandlestickChart />}
                                    size={widths.get() < 900 ? 'small' : 'medium'}
                                />
                                <Chip
                                    sx={{ ml: 1 }}
                                    label={<Typography fontSize={widths.get() < 900 ? 12 : 14}>Swap</Typography>}
                                    onClick={() => { }}
                                    icon={<SwapHoriz />}
                                    size={widths.get() < 900 ? 'small' : 'medium'}
                                />*/}
                            </Box>
                            :
                            <></>
                }
            </Box>



            <Box sx={{ height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <ButtonGroup size='small' sx={{ ml: 1 }} variant='text'>
                    <Button aria-label="Expand/Hide Top" onClick={() => { views.bottom.set(!views.get().bottom); views.center.set(true) }}>
                        {views.bottom.get() ? <ClosePane sx={{ transform: "rotate(90deg)" }} /> : <NormalView />}
                    </Button>
                    {/* <Button onClick={() => { views.center.set(!views.get().center); views.bottom.set(true) }}>
                        {views.center.get() ? <OpenPane sx={{ transform: "rotate(90deg)" }} /> : <NormalView />}
                    </Button> */}
                    {widths.get() <= 900 ? <></> :
                        <Button aria-label="Expand/Hide Side" onClick={() => { views.side.set(!views.get().side) }}>
                            {views.side.get() ? <ClosePane /> : <OpenPane />}
                        </Button>
                    }
                </ButtonGroup>
            </Box>
        </Paper>
    )
}