
import { stringShort } from '@/libs/fncs';
import {
    Tooltip,
    Typography,
} from '@mui/material';
const Decimal = require("decimal.js");

function RenderChange({ nvalue, ovalue, pfontsize, tool = true }) {
    try {
        //console.log(result);
        ovalue = ovalue ? ovalue : nvalue;
        const change = Decimal(nvalue).sub(ovalue).div(nvalue < ovalue ? nvalue : ovalue).mul("100").toFixed(2);

        //console.log("numFormat", splited[0], numFormat(splited[0]));
        return (
            tool ?
                <Tooltip title={change} placement='top' >
                    <Typography sx={{ color: change >= 0 ? "rgba(60, 179, 113, 1)" : "rgba(255, 99, 71, 1)", fontSize: pfontsize ? pfontsize : 14, display: 'flex', alignItems: "center" }}>
                        {stringShort(change, 0, 6)}%
                    </Typography>
                </Tooltip>
                :
                <Typography sx={{ color: change >= 0 ? "rgba(60, 179, 113, 1)" : "rgba(255, 99, 71, 1)", fontSize: pfontsize ? pfontsize : 14, display: 'flex', alignItems: "center" }}>
                    {stringShort(change, 0, 6)}%
                </Typography>
        )
    } catch (e) {
        return (
            <Typography sx={{ color: "rgba(60, 179, 113, 1)", fontSize: pfontsize ? pfontsize : 14, display: 'flex', alignItems: "center" }}>
                0.00%
            </Typography>
        )
    }
}

export default RenderChange;