import {
    Box,
    Paper,
    ButtonGroup,
    Button,
    Chip
} from '@mui/material';
import {
    FirstPage,
    NavigateNext,
    NavigateBefore,
    SwapHoriz,
} from '@mui/icons-material';
import { DonutLarge } from '@mui/icons-material';
import { AccountCircle } from '@mui/icons-material';
import { Whatshot } from '@mui/icons-material';
import { Visibility } from '@mui/icons-material';
import { createSvgIcon } from '@mui/material/utils';

/* const ClosePane = createSvgIcon(
    <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 14 5 L 14 12 L 14 19 L 5 19 L 5 5 z M 14 12 L 10 8 L 10 11 L 7 11 L 7 13 L 10 13 L 10 16 L 14 12 z M 16 5 L 19 5 L 19 19 L 16 19 L 16 5 z" />,
    'ClosePane',
); */
const OpenPane = createSvgIcon(
    <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 14 5 L 14 11 L 11 11 L 11 8 L 7 12 L 11 16 L 11 13 L 14 13 L 14 19 L 5 19 L 5 5 z M 16 5 L 19 5 L 19 19 L 16 19 L 16 5 z" />,
    'OpenPane',
);
/* const DownPane = createSvgIcon(
    <g transform="translate(256,0) rotate(90) scale(10.66667,10.66667)"><path d="M5,3c-1.09306,0 -2,0.90694 -2,2v14c0,1.09306 0.90694,2 2,2h14c1.09306,0 2,-0.90694 2,-2v-14c0,-1.09306 -0.90694,-2 -2,-2zM5,5h9v7v7h-9zM14,12l-4,-4v3h-3v2h3v3zM16,5h3v14h-3z" /></g>,
    'DownPane',
);
const UpPane = createSvgIcon(
    <g transform="translate(256,0) rotate(90) scale(10.66667,10.66667)"><path d="M5,3c-1.09306,0 -2,0.90694 -2,2v14c0,1.09306 0.90694,2 2,2h14c1.09306,0 2,-0.90694 2,-2v-14c0,-1.09306 -0.90694,-2 -2,-2zM5,5h9v6h-3v-3l-4,4l4,4v-3h3v6h-9zM16,5h3v14h-3z"></path></g>,
    'UpPane',
); */
/* <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M16,17H8v-2h8V17z M19,19H5V5h14V19z" />, */
const NormalView = createSvgIcon(
    <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 13 L 5 13 L 5 5 z M 5 15 L 19 15 L 19 19 L 5 19 L 5 15 z M 12 15.75 L 10.75 17 L 12 18.25 L 13.25 17 L 12 15.75 z M 8 16 A 1 1 0 0 0 7 17 A 1 1 0 0 0 8 18 A 1 1 0 0 0 9 17 A 1 1 0 0 0 8 16 z M 15 16 L 15 18 L 17 18 L 17 16 L 15 16 z" />,
    'NormalView',
);


export default function Navigator({ views, pageName, widths, pageNo, tableLoading, swaps, wallet, alerting, dataload, historyData }) {

    return (
        <Paper sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "100%", pl: 1, pr: 1, overflow: "hidden" }}>

            {
                !views.get().bottom && (pageName.get() == "bot" || pageName.get() == "privacy" || pageName.get() == "partners" || pageName.get() == "terms") ? <Box></Box> :
                    pageName.get() == "pair" ?
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%", mr: 1, overflow: "hidden" }}>
                            <Chip
                                size="small"
                                sx={{ border: swaps.get() == 0 ? 1 : 0 }}
                                label={`All ${widths.get() > 900 ? 'Swaps' : ''}`}
                                onClick={() => { if (swaps.get() != 0) { pageNo.set(0); tableLoading.set(true); historyData.set([]); swaps.set(0); } }}
                                icon={<SwapHoriz />}
                                variant={swaps.get() === 0 ? "filled" : "outlined"}
                            /*color="primary"*/
                            />
                            <Chip
                                size="small"
                                sx={{ ml: 1, border: swaps.get() == 1 ? 1 : 0 }}
                                label={`Big ${widths.get() > 900 ? 'Swaps' : ''}`}
                                onClick={() => { if (swaps.get() != 1) { pageNo.set(0); tableLoading.set(true); historyData.set([]); swaps.set(1); } }}
                                icon={<DonutLarge />}
                                variant={swaps.get() === 1 ? "filled" : "outlined"}
                            /*color="primary"*/
                            />
                            <Chip
                                size="small"
                                sx={{ ml: 1, border: swaps.get() == 2 && wallet.get().address != '' ? 1 : 0 }}
                                label={`My ${widths.get() > 900 ? 'Swaps' : ''}`}
                                onClick={() => {
                                    if (wallet.get().connected && swaps.get() != 2) {
                                        pageNo.set(0); tableLoading.set(true); historyData.set([]); swaps.set(2)
                                    } else {
                                        alerting.merge({
                                            status: true,
                                            duration: 5000,
                                            type: "error",
                                            message: "Connect wallet to use this feature.",
                                        })
                                    }
                                }}
                                icon={<AccountCircle />}
                                variant={swaps.get() === 2 ? "filled" : "outlined"}
                            /*color="primary"*/
                            />
                        </Box>
                        :
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%", mr: 1, overflow: "hidden" }}>
                            <Chip
                                size="small"
                                label="Trending Now"
                                onClick={() => { }}
                                icon={<Whatshot />}
                                variant="outlined"
                            /*color="primary"*/
                            />
                        </Box>
            }
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {
                    pageName.get() == "bot" || pageName.get() == "privacy" || pageName.get() == "partners" || pageName.get() == "terms" || (pageName.get() != "pair" && !views.get().center && views.get().bottom) ? <ButtonGroup size='small' variant='text'>
                        <Button aria-label="Expand/Hide Bottom" onClick={() => { views.center.set(!views.get().center); views.bottom.set(true) }}>
                            {views.center.get() ? <OpenPane fontSize='small' sx={{ transform: "rotate(90deg)" }} /> : <NormalView fontSize='small' />}
                        </Button>
                    </ButtonGroup>
                        :
                        <ButtonGroup size='small' variant='text'>
                            {
                                pageNo.get() > 1 ?
                                    <Button aria-label="First Page" onClick={() => { if (pageNo.get() > 0) { tableLoading.set(true); pageNo.set(0); } }} >
                                        <FirstPage fontSize='small' />
                                    </Button> : <></>
                            }
                            {
                                pageNo.get() > 0 ?
                                    <Button aria-label="Previous Page" onClick={() => { if (pageNo.get() > 0) { tableLoading.set(true); pageNo.set(parseInt(pageNo.get() - 1)); } }} >
                                        <NavigateBefore fontSize='small' />
                                    </Button> : <></>
                            }
                            <Button aria-label="Page No" disabled>
                                {parseInt(pageNo.get() + 1)}
                            </Button>
                            <Button aria-label="Next Page" onClick={() => { dataload.set(true); tableLoading.set(true); pageNo.set(parseInt(pageNo.get() + 1)); }} >
                                <NavigateNext fontSize='small' />
                            </Button>
                            <Button aria-label="Expand/Hide Bottom" onClick={() => { views.center.set(!views.get().center); views.bottom.set(true) }}>
                                {views.center.get() ? <OpenPane fontSize='small' sx={{ transform: "rotate(90deg)" }} /> : <NormalView fontSize='small' />}
                            </Button>
                        </ButtonGroup>
                }
            </Box>
        </Paper>
    )
}