import { Home } from '@mui/icons-material';
import { Search } from '@mui/icons-material';
import { Star } from '@mui/icons-material';
import { DarkMode } from '@mui/icons-material';
import { Favorite } from '@mui/icons-material';
import { Token } from '@mui/icons-material';
import { AccountBalance } from '@mui/icons-material';
import { JoinRight } from '@mui/icons-material';
import { ArrowBack } from '@mui/icons-material';
import { LightMode } from '@mui/icons-material';
import { Info } from '@mui/icons-material';
import { Menu } from '@mui/icons-material';
import {
    Box, Button, Paper, Typography,
} from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';

/* const ClosePane = createSvgIcon(
  <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 14 5 L 14 12 L 14 19 L 5 19 L 5 5 z M 14 12 L 10 8 L 10 11 L 7 11 L 7 13 L 10 13 L 10 16 L 14 12 z M 16 5 L 19 5 L 19 19 L 16 19 L 16 5 z" />,
  'ClosePane',
);
const OpenPane = createSvgIcon(
  <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 14 5 L 14 11 L 11 11 L 11 8 L 7 12 L 11 16 L 11 13 L 14 13 L 14 19 L 5 19 L 5 5 z M 16 5 L 19 5 L 19 19 L 16 19 L 16 5 z" />,
  'OpenPane',
);
const DownPane = createSvgIcon(
  <g transform="translate(256,0) rotate(90) scale(10.66667,10.66667)"><path d="M5,3c-1.09306,0 -2,0.90694 -2,2v14c0,1.09306 0.90694,2 2,2h14c1.09306,0 2,-0.90694 2,-2v-14c0,-1.09306 -0.90694,-2 -2,-2zM5,5h9v7v7h-9zM14,12l-4,-4v3h-3v2h3v3zM16,5h3v14h-3z" /></g>,
  'DownPane',
);
const UpPane = createSvgIcon(
  <g transform="translate(256,0) rotate(90) scale(10.66667,10.66667)"><path d="M5,3c-1.09306,0 -2,0.90694 -2,2v14c0,1.09306 0.90694,2 2,2h14c1.09306,0 2,-0.90694 2,-2v-14c0,-1.09306 -0.90694,-2 -2,-2zM5,5h9v6h-3v-3l-4,4l4,4v-3h3v6h-9zM16,5h3v14h-3z"></path></g>,
  'UpPane',
);
const NormalView = createSvgIcon(
  <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 13 L 5 13 L 5 5 z M 5 15 L 19 15 L 19 19 L 5 19 L 5 15 z M 12 15.75 L 10.75 17 L 12 18.25 L 13.25 17 L 12 15.75 z M 8 16 A 1 1 0 0 0 7 17 A 1 1 0 0 0 8 18 A 1 1 0 0 0 9 17 A 1 1 0 0 0 8 16 z M 15 16 L 15 18 L 17 18 L 17 16 L 15 16 z" />,
  'NormalView',
); */

const BlockChain = createSvgIcon(
    <path d="M 12 2 C 11.194621 2 10.416027 2.3222541 9.8457031 2.8925781 L 7.4609375 5.2773438 L 6.7558594 5.9824219 L 2.8945312 9.8457031 C 2.3226384 10.416122 2 11.194621 2 12 C 2 12.80416 2.3200769 13.583271 2.8925781 14.154297 L 4.4023438 15.664062 L 5.2890625 16.550781 L 9.8457031 21.107422 C 10.416027 21.677746 11.194621 22 12 22 C 12.805379 22 13.58707 21.680152 14.158203 21.101562 L 19.427734 15.833984 L 21.109375 14.15625 L 21.111328 14.154297 C 21.671704 13.58948 21.996533 12.822981 22 12.019531 C 22.006866 11.203615 21.680104 10.418385 21.107422 9.8457031 L 20.339844 9.0800781 L 17.072266 5.8105469 L 14.158203 2.8945312 C 13.58578 2.3191428 12.805379 2 12 2 z M 12 4 C 12.288621 4 12.540657 4.104076 12.740234 4.3046875 L 12.742188 4.3066406 L 14.246094 5.8105469 L 11.828125 8.2304688 L 9.5820312 5.9824219 L 11.259766 4.3066406 C 11.463442 4.1029646 11.711379 4 12 4 z M 15.658203 7.2246094 L 17.599609 9.1679688 L 15.167969 11.572266 L 13.242188 9.6445312 L 15.658203 7.2246094 z M 8.1699219 7.3984375 L 10.414062 9.6445312 L 5.8144531 14.25 L 4.3046875 12.740234 L 4.3046875 12.738281 C 4.1011887 12.535354 4 12.28984 4 12 C 4 11.711379 4.1025336 11.4653 4.3066406 11.261719 L 4.3066406 11.259766 L 8.1699219 7.3984375 z M 19.015625 10.582031 L 19.693359 11.259766 C 19.900237 11.466643 20.002617 11.714496 20 12.003906 L 20 12.005859 L 20 12.009766 C 19.999 12.289633 19.894546 12.541359 19.691406 12.746094 L 18.013672 14.419922 L 16.582031 12.986328 L 19.015625 10.582031 z M 11.828125 11.060547 L 16.599609 15.833984 L 12.738281 19.695312 L 12.734375 19.697266 C 12.539503 19.89463 12.288621 20 12 20 C 11.711379 20 11.463442 19.897035 11.259766 19.693359 L 7.2304688 15.664062 L 11.828125 11.060547 z" />,
    'BlockChain',
);

export default function BottomMenu({ menuStatus, sideMenuStatus, selAcc, pageName, themeMode, tvw, popStatus, popType, wallet }) {

    if (menuStatus.get() == "block" || sideMenuStatus.get() == "block") {
        return (
            <Paper sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "100%" }}>
                <Button color='inherit' onClick={() => {
                    sideMenuStatus.set("none");
                    menuStatus.set("none");
                }} sx={{ width: "100%", height: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}>
                        <ArrowBack sx={{ display: "flex", alignItems: "center", width: "20px", height: "100%", justifyContent: "start" }} />
                        <Typography sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%", justifyContent: "center", fontSize: 14 }} >Back</Typography>
                    </Box>
                </Button>
            </Paper>
        )
    } else {
        return (
            <Paper sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "100%", overflow: "auto" }}>
                <Button color='inherit' sx={{ width: "100%", height: "100%" }} onClick={() => {
                    sideMenuStatus.set("none");
                    menuStatus.set("block")
                }}>
                    <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Menu fontSize='small' />
                        <Typography sx={{ fontSize: 10, whiteSpace: "nowrap" }}>
                            Menu
                        </Typography>
                    </Box>
                </Button>
                {/* <Box sx={{ width: "100%", height: "100%" }}>
                <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Home fontSize='small' />
                    <Typography sx={{ fontSize: 10, whiteSpace: "nowrap" }}>
                        Home
                    </Typography>
                </Box>
            </Box> */}
                <Button color='inherit' sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                    onClick={() => {
                        menuStatus.set("none");
                        selAcc.set(2);
                        sideMenuStatus.set("block");
                    }}>
                    <BlockChain fontSize='small' />
                    <Typography sx={{ fontSize: 10, whiteSpace: "nowrap" }}>
                        All Chain
                    </Typography>
                </Button>
                <Button color='inherit' sx={{ width: "100%", height: "100%" }} onClick={() => {
                    const localwallet = JSON.parse(localStorage.getItem("cgwallet"));
                    if (localwallet && localwallet.connected && !wallet.get().connected) {
                        //console.log("localwallet", localwallet);
                        wallet.merge(localwallet);
                    }
                    popStatus.set(true);
                    popType.set(4);
                }}>
                    <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Search fontSize='small' />
                        <Typography sx={{ fontSize: 10, whiteSpace: "nowrap" }}>
                            Search
                        </Typography>
                    </Box>
                </Button>
                <Button color='inherit' sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                    onClick={() => {
                        menuStatus.set("none");
                        selAcc.set(3);
                        sideMenuStatus.set("block");
                    }}>
                    <Favorite fontSize='small' /* color='error' */ />
                    <Typography sx={{ fontSize: 10, whiteSpace: "nowrap" }}>
                        Favorite
                    </Typography>
                </Button>
                {
                    pageName.get() == 'dex' || pageName.get() == 'token' || pageName.get() == 'pair' ?
                        <Button color='inherit' sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                            onClick={() => {
                                menuStatus.set("none");
                                selAcc.set(1);
                                sideMenuStatus.set("block");
                            }}>
                            {
                                pageName.get() == "pair" ? <JoinRight fontSize='small' />
                                    : pageName.get() == "token" ? <Token fontSize='small' />
                                        : <AccountBalance fontSize='small' />
                            }
                            <Typography sx={{ fontSize: 10, whiteSpace: "nowrap" }}>
                                {
                                    pageName.get() == "pair" ? "Pair Info"
                                        : pageName.get() == "token" ? "Token Info"
                                            : "DEX Info"
                                }
                            </Typography>
                        </Button>
                        :
                        <Button color='inherit' sx={{ width: "100%", height: "100%" }} onClick={() => {
                            themeMode.set(themeMode.get() == "dark" ? "light" : "dark");
                            localStorage.setItem("theme", themeMode.get());
                            if (pageName.get() == "pair") tvw.get().changeTheme(themeMode.get());
                        }}>
                            <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                {themeMode.get() == "dark" ? <LightMode fontSize='small' /> : <DarkMode fontSize='small' />}
                                <Typography sx={{ fontSize: 10 }}>
                                    Theme
                                </Typography>
                            </Box>
                        </Button>
                }
            </Paper>
        )
    }
}