import {
    Box,
} from '@mui/material';
import { myLoader } from '@/libs/fncs';
import Image from 'next/legacy/image';
import { isApp } from '@/libs/store';

export default function SideBottomAd({ adtype }) {

    return (
        <Box sx={{ width: "100%", height: "100%", position: "relative", borderRadius: 1, overflow: "hidden" }}>
            {
                adtype.get() == "own" ?
                    <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ width: "300px", height: "250px", position: "relative", borderRadius: 1, overflow: "hidden" }}>
                            <video width='300px' height='250px' muted autoPlay loop >
                                <source src="/320x50.mp4" type="video/mp4" />
                            </video>
                        </Box>
                    </Box>
                    :
                    adtype.get() == "bm" && !isApp ?
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <ins className="62f4dbd592db9581236c78c5" style={{ display: "inline-block", width: '300px', height: '250px' }}></ins>
                        </Box>
                        :
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <iframe title="320x250 Ad" data-aa='2189429' src='//ad.a-ads.com/2189431?size=300x250' style={{ width: '300px', height: '250px', border: '0px', padding: 0, overflow: 'hidden', backgroundColor: 'transparent' }}></iframe>
                        </Box>
            }
        </Box>
    )

}