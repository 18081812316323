
import {
    Avatar
} from '@mui/material';
import { myLoader, stringAvatar } from '@/libs/fncs';
import Image from 'next/legacy/image';

function RenderLogo({ image, value, width, height }) {

    return (
        image ?
            <Image loader={myLoader} unoptimized={true} src={image} alt="Icon" layout="fill" objectFit={'contain'} quality={100} priority={true} />
            :
            <Avatar style={{ height, width, fontSize: "14px", fontWeight: "500" }} {...stringAvatar(value)}></Avatar>
    )
}

export default RenderLogo;