
import {
    Box, Chip, Divider, Paper, Tooltip, Typography,
} from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import Image from 'next/legacy/image';
//import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { myLoader, stringShort } from '@/libs/fncs';
import RenderLogo from './RenderLogo';
import Clink from '@/components/Clink';
import { dataloadd, networkList } from '@/libs/store';
import { TrendingUp } from '@mui/icons-material';
import { TrendingDown } from '@mui/icons-material';
import { FiberNew } from '@mui/icons-material';
import { useHookstate } from '@hookstate/core';
import { useEffect } from 'react';


export default function TrendingTable({ trendtype, data, pageName, tableLoading, router }) {

    //console.log(data.get())

    const RenderTrendingTable = (props) => {

        if (props.row && props.row.address) {
            var image = props.row && props.row.t0 ? props.row.t0.image : null;
            var image1 = props.row && props.row.t1 ? props.row.t1.image : null;
            var symbol = props.row && props.row.t0 ? props.row.t0.symbol : "NA";
            var symbol1 = props.row && props.row.t1 ? props.row.t1.symbol : "NA";
            var chainImage = props.row && props.row.chain ? networkList[props.row.chain].image : "/sf/logo.svg";
            var change = props.row && props.row.change ? props.row.change : 0;

            return (
                <Clink router={router} pageValue={"pair"} chainValue={props.row.chain} addValue={props.row.address} href={`/${props.row.chain}/pair/${props.row.address}`}>
                    <Box sx={{ width: "100%", display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                        <Box sx={{ display: 'flex', alignItems: "center", pr: 1 }}>
                            <Box sx={{ mr: 0.5, display: "flex", alignItems: "center", position: 'relative', width: "25px", height: "25px" }}>
                                <Image src={chainImage} alt="Logo" layout="fill" objectFit={'contain'} quality={70} priority={true} />
                            </Box>
                            <Box sx={{ mr: 0.5, display: "flex", alignItems: "center" }}>
                                <ArrowRightAlt />
                            </Box>
                            {/* <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <RenderLogo image={image} value={symbol} width={"25px"} height={"25px"} />
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", pl: 0.5 }}>
                                    <RenderLogo image={image1} value={symbol1} width={"25px"} height={"25px"} />
                                </Box>
                            </Box> */}
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ whiteSpace: "nowrap", fontSize: 16 }}>{stringShort(symbol, 0, 5)}</Typography>
                                <Typography sx={{ whiteSpace: "nowrap", fontSize: 16, pl: 0.5, pr: 0.5 }}>/</Typography>
                                <Typography sx={{ whiteSpace: "nowrap", fontSize: 16 }}>{stringShort(symbol1, 0, 5)}</Typography>
                            </Box>
                        </Box>
                        {
                            trendtype === 2 ? <></> :
                                <Typography sx={{ color: change >= 0 ? "rgba(60, 179, 113, 1)" : "rgba(255, 99, 71, 1)", fontSize: 14 }}>
                                    {change ? stringShort(change, 0, 5) : 0}%
                                </Typography>
                        }
                    </Box>
                </Clink>
            )
        } else {
            return (
                <></>
            )
        }
    }

    return (
        <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
            <Paper elevation={2} sx={{ width: "100%", minWidth: "300px", height: "40px", position: 'sticky', top: 0, zIndex: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center", justifyContent: 'space-between', pl: 1 }}>
                    {trendtype ? trendtype == 1 ?
                        <Chip
                            label="Top Losers"
                            icon={<TrendingDown />}
                            size='small'
                        /> : <Chip
                            label="New Pairs"
                            icon={<FiberNew />}
                            size='small'
                        /> : <Chip
                        label="Top Gainers"
                        icon={<TrendingUp />}
                        size='small'
                    />}
                </Box>
            </Paper><Box sx={{ width: "100%", height: `calc(100% - 40px)`, zIndex: 1 }}>
                {
                    (trendtype === 2 ? data.get().newpair : trendtype === 1 ? data.get().losers : data.get().gainers).map((row, i) => {
                        const id = parseInt(i + 1);
                        return (
                            <Box key={id} sx={{ width: "100%", minWidth: "300px", height: "48px" }}>
                                {
                                    id === 1 ? <></> : <Divider />
                                }
                                <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                    <Box sx={{ width: "100%", height: "100%", minWidth: "300px", pl: 1, pr: 1 }}>
                                        <RenderTrendingTable row={row} />
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}