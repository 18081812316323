import { networkList, seoList } from "./store";

const Decimal = require("decimal.js");

export function getseo(chain, pagetype, address) {

    var chainfound = chain ? networkList[chain] : networkList['all'];
    var pagefound = pagetype ? seoList[pagetype] : seoList['home'];
    //console.log(networkList, seoList, chainfound, pagefound);
    chainfound = chainfound && chainfound.path ? chainfound : networkList['all'];
    pagefound = pagefound && pagefound.key ? pagefound : seoList['home'];

    //console.log(chainfound, pagefound);
    var titleData = `${address ? `${address} | ` : ''}${pagefound.title} ${pagetype == 'bot' || pagetype == 'terms' || pagetype == 'privacy' || pagetype == 'partners' ? '' : chainfound.name.toUpperCase()} (${chainfound.path.toUpperCase()}) Chain${pagetype == 'dex' || pagetype == 'token' || pagetype == 'pair' ? '' : ' | DEX Tracker Tools'}`;
    var descriptionData = `${address ? `${address} | ` : ''}${pagefound.title} ${chainfound.name.toUpperCase()} (${chainfound.path.toUpperCase()}) Chain${pagetype == 'dex' || pagetype == 'token' || pagetype == 'pair' ? '' : ' | DEX Tracker Tools.'} ${address ? `CryptoGems is a DEX tracker tool that provides advanced tools like charts, order history, volume, transaction count, market capital, and more to analyze your pair swap data.` : `CryptoGems is a DEX tracker tool that helps to find all DEX-related tokens and pairs traded in multiple chains. It helps to trade all markets from one place using advanced tools like charts, order history, volume, transaction count, market capital, etc.`}`;
    return {
        key: `${chainfound.path}${pagefound.key}${address ? address : ''}`,
        chain: chainfound.path,
        pagetype: pagefound.key,
        addressData: address ? address : null,
        titleData,
        descriptionData
    };
}

export function favs(pair, favList, alerting, add = false) {
    try {
        var tempfav = favList.get({ noproxy: true });
        if (add && !tempfav[pair.address]) {
            tempfav[pair.address] = pair;
            favList.set(tempfav);
            localStorage.setItem("cgfav", JSON.stringify(tempfav));
            alerting.merge({
                status: true,
                duration: 5000,
                type: "success",
                message: "Added to Favourites.",
            })
        } else {
            delete tempfav[pair.address];
            favList.set(tempfav);
            localStorage.setItem("cgfav", JSON.stringify(tempfav));
            alerting.merge({
                status: true,
                duration: 5000,
                type: "error",
                message: "Removed from Favourites.",
            })
        }
    } catch (e) {
        //console.log("fav area", e);
    }
}

export function myLoader({ src, width, height, quality, urlType }) {
    /*
    var url = src;
    if (urlType) {
        url = `https://imagizer.imageshack.com/v2/${width}x${height || width}q${quality || 90}${src}`
    }
    //console.log(src, urlType, width, url);
    */
    return src
}

function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name) {
    name = name ? name : "NA";
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.charAt(0)}`,
    };
}

export function stringShort(value, type = 0, length = 5) {
    var result;
    value = value ? value.toString() : "NA";

    if (value.length > length) {
        if (type) {
            result = value.slice(0, length) + "..." + value.slice(-length);
        } else {
            result = value.slice(0, length) + "..";
        }
    } else {
        result = value;
    }
    return result;
}

export function numFormat(value, type = 0, deci = 0, afterdeci = false) {
    var result;
    if (value > 0) {
        if (type > 0) {
            var precision = -Math.floor(Math.log10(value) + 1);
            precision = precision > 0 ? precision + deci : deci;
            //console.log(value, precision);
            value = Number(value).toFixed(precision);
            result = value;
        }
        if (type < 2) {
            value = value.toString();
            var dotArr = value.split(".");
            var strFormat = dotArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            result = afterdeci ? (strFormat ? strFormat : "0").concat(Number(dotArr[1]) > 0 ? "." + dotArr[1] : "") : strFormat ? strFormat : "0";
        }
    } else {
        result = "0";
    }
    return result;
}

export function numChange(newNumber, oldNumber) {
    return Decimal(newNumber).sub(oldNumber).div(newNumber < oldNumber ? newNumber : oldNumber).mul("100").toFixed(2);
}

export function numShort(num, digits) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "Qa" },
        { value: 1e18, symbol: "Qi" },
        { value: 1e21, symbol: "Sx" },
        { value: 1e24, symbol: "Sp" },
        { value: 1e27, symbol: "Oc" },
        { value: 1e30, symbol: "No" },
        { value: 1e33, symbol: "Dc" },
        { value: 1e36, symbol: "Ud" },
        { value: 1e39, symbol: "Dd" },
        { value: 1e42, symbol: "Td" },
        { value: 1e45, symbol: "Qad" },
        { value: 1e48, symbol: "Qid" },
        { value: 1e51, symbol: "Sxd" },
        { value: 1e54, symbol: "Spd" },
        { value: 1e57, symbol: "Ocd" },
        { value: 1e60, symbol: "Nod" },
        { value: 1e63, symbol: "Vg" },
        { value: 1e66, symbol: "Uvg" },
        { value: 1e69, symbol: "Dvg" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
        return Number(num) >= item.value;
    });
    //console.log("item", item);
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : Number(Number(num).toFixed(digits));
}