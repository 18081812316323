import Clink from '@/components/Clink';
import { myLoader } from '@/libs/fncs';
import { Handshake, Token } from '@mui/icons-material';
import { FindInPage } from '@mui/icons-material';
import { DonutLarge } from '@mui/icons-material';
import { SmartToy } from '@mui/icons-material';
import { Gavel } from '@mui/icons-material';
import { Search } from '@mui/icons-material';
import { LightMode } from '@mui/icons-material';
import { AccountBalance } from '@mui/icons-material';
import { JoinRight } from '@mui/icons-material';
import { DarkMode } from '@mui/icons-material';
import { TrendingUp } from '@mui/icons-material';
import { Shield } from '@mui/icons-material';
import { ShowChart } from '@mui/icons-material';
import { FiberNew } from '@mui/icons-material';
import { TravelExplore } from '@mui/icons-material';
import { Menu } from '@mui/icons-material';
import { MenuOpen } from '@mui/icons-material';
import {
    Box,
    Divider,
    ListItemButton,
    Paper, Stack, Tooltip, Typography,
} from '@mui/material';
import Image from 'next/legacy/image';
import { AccountBalanceWallet } from '@mui/icons-material';
import { Share } from '@mui/icons-material';

export default function SideMenu({ menuStatus, sideMenuStatus, chainName, pageName, theme, themeMode, popType, popStatus, tvw, router, wallet, views }) {


    return (
        <Box sx={{ display: { xs: menuStatus.get() == "none" ? "none" : "block", md: "block" }, width: { xs: (menuStatus.get() == "none" ? 0 : `calc(100% - 8px)`), md: (menuStatus.get() == "none" ? "50px" : "320px") }, height: { xs: (menuStatus.get() == "none" ? 0 : `calc(100% - 58px)`), md: `calc(100% - 8px)` }, position: "fixed", zIndex: 5 }}>
            <Paper sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", overflow: "auto" }}>
                <Stack spacing={0.5}>
                    <Box>
                        <ListItemButton aria-label="Menu Open/Close" onClick={() => {
                            sideMenuStatus.set("none");
                            menuStatus.set(menuStatus.get() == "block" ? "none" : 'block');
                        }} sx={{ color: theme.palette.primary.main, borderRadius: 2, p: 1, mt: 0.5, ml: 0.5, mr: 0.5 }}>
                            {menuStatus.get() == "block" ?
                                <MenuOpen />
                                :
                                <Menu />
                            }
                            <Box sx={{ display: menuStatus.get() == "none" ? "none" : "flex", alignItems: "center", pl: menuStatus.get() == "none" ? 0 : 2 }}>
                                <Box sx={{ display: "flex", alignItems: "center", position: 'relative', width: "24px", height: "24px" }}>
                                    <Image src="/sf/logo.svg" alt="logo" layout="fill" objectFit={'contain'} quality={70} priority={true} />
                                </Box>
                                <Typography sx={{ fontSize: 16, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 1, color: theme.palette.primary.main, whiteSpace: "nowrap" }}>
                                    CryptoGems
                                </Typography>
                            </Box>
                        </ListItemButton>
                    </Box>
                    <Box>
                        <Tooltip title={"Search Pairs"} placement="right" sx={{ overflow: "auto" }}>
                            <ListItemButton onClick={() => {
                                const localwallet = JSON.parse(localStorage.getItem("cgwallet"));
                                if (localwallet && localwallet.connected && !wallet.get().connected) {
                                    //console.log("localwallet", localwallet);
                                    wallet.merge(localwallet);
                                }
                                popStatus.set(true);
                                popType.set(4);
                            }} sx={{ borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }}>
                                <Search />
                                <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                    Search Pairs
                                </Typography>
                            </ListItemButton>
                        </Tooltip>
                    </Box>
                    <Divider />
                    <Box >
                        <Clink router={router} pageValue={"home"} href={`/${chainName.get() && chainName.get() != "all" ? chainName.get() : ''}`}>
                            <Tooltip title={"Token List"} placement="right" sx={{ overflow: "auto" }}>
                                <ListItemButton sx={{ color: pageName.get() == "home" ? theme.palette.primary.main : "", borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }} aria-label="home" selected={pageName.get() == "home" ? true : false} onClick={() => { sideMenuStatus.set("none"); menuStatus.set("none"); }}>
                                    <Token />
                                    <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                        Token List
                                    </Typography>
                                </ListItemButton>
                            </Tooltip>
                        </Clink>
                    </Box>
                    <Box >
                        <Clink router={router} pageValue={"dexlist"} href={"/" + (chainName.get() ? chainName.get() : "all") + "/dexlist"}>
                            <Tooltip title={"DEX Explorer"} placement="right" sx={{ overflow: "auto" }}>
                                <ListItemButton sx={{ color: pageName.get() == "dexlist" ? theme.palette.primary.main : "", borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }} aria-label="dexlist" selected={pageName.get() == "dexlist" ? true : false} onClick={() => { sideMenuStatus.set("none"); menuStatus.set("none"); }}>
                                    <AccountBalance />
                                    <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                        DEX Explorer
                                    </Typography>
                                </ListItemButton>
                            </Tooltip>
                        </Clink>
                    </Box>
                    <Box >
                        <Clink router={router} pageValue={"pairlist"} href={"/" + (chainName.get() ? chainName.get() : "all") + "/pairlist"}>
                            <Tooltip title={"Pair Explorer"} placement="right" sx={{ overflow: "auto" }}>
                                <ListItemButton sx={{ color: pageName.get() == "pairlist" ? theme.palette.primary.main : "", borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }} aria-label="pairlist" selected={pageName.get() == "pairlist" ? true : false} onClick={() => { sideMenuStatus.set("none"); menuStatus.set("none"); }}>
                                    <JoinRight />
                                    <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                        Pair Explorer
                                    </Typography>
                                </ListItemButton>
                            </Tooltip>
                        </Clink>
                    </Box>
                    <Box >
                        <Clink router={router} pageValue={"newpair"} href={"/" + (chainName.get() ? chainName.get() : "all") + "/newpair"}>
                            <Tooltip title={"New pairs"} placement="right" sx={{ overflow: "auto" }}>
                                <ListItemButton sx={{ color: pageName.get() == "newpair" ? theme.palette.primary.main : "", borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }} aria-label="newpair" selected={pageName.get() == "newpair" ? true : false} onClick={() => { sideMenuStatus.set("none"); menuStatus.set("none"); }}>
                                    <FiberNew />
                                    <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                        New pairs
                                    </Typography>
                                </ListItemButton>
                            </Tooltip>
                        </Clink>
                    </Box>
                    <Box >
                        <Clink router={router} pageValue={"bigswap"} href={"/" + (chainName.get() ? chainName.get() : "all") + "/bigswap"}>
                            <Tooltip title={"Big Swaps"} placement="right" sx={{ overflow: "auto" }}>
                                <ListItemButton sx={{ color: pageName.get() == "bigswap" ? theme.palette.primary.main : "", borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }} aria-label="bigswap" selected={pageName.get() == "bigswap" ? true : false} onClick={() => { sideMenuStatus.set("none"); menuStatus.set("none"); }}>
                                    <DonutLarge />
                                    <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                        Big Swaps
                                    </Typography>
                                </ListItemButton>
                            </Tooltip>
                        </Clink>
                    </Box>
                    <Box >
                        <Clink router={router} pageValue={"topgainers"} href={"/" + (chainName.get() ? chainName.get() : "all") + "/topgainers"}>
                            <Tooltip title={`Gainers & Losers`} placement="right" sx={{ overflow: "auto" }}>
                                <ListItemButton sx={{ color: pageName.get() == "topgainers" ? theme.palette.primary.main : "", borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }} aria-label="topgainers" selected={pageName.get() == "topgainers" ? true : false} onClick={() => { sideMenuStatus.set("none"); menuStatus.set("none"); }}>
                                    <TrendingUp />
                                    <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                        {`Gainers & Losers`}
                                    </Typography>
                                </ListItemButton>
                            </Tooltip>
                        </Clink>
                    </Box>
                    <Divider />
                    <Box >
                        <Clink router={router} pageValue={"bot"} href={"/all/bot"}>
                            <Tooltip title={"Price Bot"} placement="right" sx={{ overflow: "auto" }}>
                                <ListItemButton sx={{ color: pageName.get() == "bot" ? theme.palette.primary.main : "", borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }} aria-label="bot" selected={pageName.get() == "bot" ? true : false} onClick={() => { sideMenuStatus.set("none"); menuStatus.set("none"); chainName.set("all"); views.center.set(true); }}>
                                    <SmartToy />
                                    <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                        Price Bot
                                    </Typography>
                                </ListItemButton>
                            </Tooltip>
                        </Clink>
                    </Box>
                    <Box >
                        <Clink router={router} pageValue={"partners"} href={"/all/partners"}>
                            <Tooltip title={"Partners"} placement="right" sx={{ overflow: "auto" }}>
                                <ListItemButton sx={{ color: pageName.get() == "partners" ? theme.palette.primary.main : "", borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }} selected={pageName.get() == "partners" ? true : false} onClick={() => { sideMenuStatus.set("none"); menuStatus.set("none"); chainName.set("all");; views.center.set(true); }}>
                                    <Handshake />
                                    <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                        Partners
                                    </Typography>
                                </ListItemButton>
                            </Tooltip>
                        </Clink>
                    </Box>
                    <Box >
                        <Clink router={router} pageValue={"privacy"} href={"/all/privacy"}>
                            <Tooltip title={"Privacy Policy"} placement="right" sx={{ overflow: "auto" }}>
                                <ListItemButton sx={{ color: pageName.get() == "privacy" ? theme.palette.primary.main : "", borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }} selected={pageName.get() == "privacy" ? true : false} onClick={() => { sideMenuStatus.set("none"); menuStatus.set("none"); chainName.set("all");; views.center.set(true); }}>
                                    <Shield />
                                    <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                        Privacy Policy
                                    </Typography>
                                </ListItemButton>
                            </Tooltip>
                        </Clink>
                    </Box>
                    <Box >
                        <Clink router={router} pageValue={"terms"} href={"/all/terms"}>
                            <Tooltip title={`Terms & Condition`} placement="right" sx={{ overflow: "auto" }}>
                                <ListItemButton sx={{ color: pageName.get() == "terms" ? theme.palette.primary.main : "", borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }} aria-label="terms" selected={pageName.get() == "terms" ? true : false} onClick={() => { sideMenuStatus.set("none"); menuStatus.set("none"); chainName.set("all");; views.center.set(true); }}>
                                    <Gavel />
                                    <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                        {`Terms & Condition`}
                                    </Typography>
                                </ListItemButton>
                            </Tooltip>
                        </Clink>
                    </Box>
                </Stack>

                <Stack spacing={0.5} sx={{ pb: 0.5 }}>
                    <Box >
                        <Tooltip title={`Connect Wallet`} placement="right" sx={{ overflow: "auto" }}>
                            <ListItemButton aria-label="wallet connect" onClick={() => {
                                //onInitializeSignClient() 
                                const localwallet = JSON.parse(localStorage.getItem("cgwallet"));
                                if (localwallet && localwallet.connected && !wallet.get().connected) {
                                    //console.log("localwallet", localwallet);
                                    wallet.merge(localwallet);
                                }
                                popStatus.set(true);
                                popType.set(3);
                            }} sx={{ borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }}>
                                <AccountBalanceWallet />
                                <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                    {`Connect Wallet`}
                                </Typography>
                            </ListItemButton>
                        </Tooltip>
                    </Box>
                    <Box >
                        <Tooltip title={`Share & Support Us`} placement="right" sx={{ overflow: "auto" }}>
                            <ListItemButton aria-label="share" onClick={() => {
                                //onInitializeSignClient() 
                                popStatus.set(true);
                                popType.set(2);
                            }} sx={{ borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }}>
                                <Share />
                                <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                    {`Share & Support Us`}
                                </Typography>
                            </ListItemButton>
                        </Tooltip>
                    </Box>
                    <Box>
                        <Tooltip title={"Change Theme"} placement="right" sx={{ overflow: "auto" }}>
                            <ListItemButton onClick={() => {
                                themeMode.set(themeMode.get() == "dark" ? "light" : "dark");
                                localStorage.setItem("theme", themeMode.get());
                                if (pageName.get() == "pair") {
                                    tvw.get().changeTheme(themeMode.get());
                                }
                                sideMenuStatus.set("none"); menuStatus.set("none");
                            }} sx={{ borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }}>
                                {themeMode.get() == "dark" ? <LightMode /> : <DarkMode />}
                                <Typography sx={{ fontSize: 15, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                    {themeMode.get() == "dark" ? "Switch to Light Theme" : "Switch to Dark Theme"}
                                </Typography>
                            </ListItemButton>
                        </Tooltip>
                    </Box>
                    <Box>
                        <Clink router={router} ext={true} href={"https://www.pennytoken.org/"}>
                            <ListItemButton onClick={() => { }} sx={{ borderRadius: 2, p: 1, ml: 0.5, mr: 0.5 }}>
                                <Box sx={{ display: "flex", alignItems: "center", position: 'relative', width: "24px", height: "24px" }}>
                                    <Image src="/sf/penny.svg" alt="logo" layout="fill" objectFit={'contain'} quality={70} priority={true} />
                                </Box>
                                <Typography sx={{ fontSize: 16, display: menuStatus.get(), pl: menuStatus.get() == "none" ? 0 : 2, whiteSpace: "nowrap" }}>
                                    Powered By Penny
                                </Typography>
                            </ListItemButton>
                        </Clink>
                    </Box>
                </Stack>
            </Paper>
        </Box>
    )

}