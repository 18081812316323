import {
    Box,
    Divider,
    Paper,
    Typography
} from '@mui/material';
import { stringShort } from '@/libs/fncs';

export default function SideContentCard({ title, theme, height, ptop, bottom, children }) {

    return (
        <Paper sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: height ? height : "55px", p: 0.5, mb: bottom }}>
            <Box sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 12, textAlign: 'right', width: "100%", color: theme.palette.primary.main }}>
                    {stringShort(title, 0, 32)}
                </Typography>
            </Box>
            {/* <Divider sx={{ width: "100%" }} /> */}
            <Box sx={{ width: "100%", display: "flex", alignItems: "center", pt: ptop || ptop == 0 ? ptop : 0.5 }}>
                {children}
            </Box>
        </Paper>
    )

}