
//import { themed } from '@/libs/store';
import { useHookstate } from '@hookstate/core';
import {
    Box,
} from '@mui/material';
import { useEffect } from 'react';

export default function Chart24hr({ chartid, chartdata }) {

    //const themeMode = useHookstate(themed);


    useEffect(() => {
        drawLineCharts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function drawLineCharts() {
        Window.lineSeries = {};
        try {
            //var candlestickSeries;
            //var histogramSeries;        
            var data = chartdata;
            const removeNull = data.filter(n => n);
            var newData = [];
            for (var i = 0; i < 12; i++) {
                if (data[i]) {

                } else {
                    if (i == 0) {
                        data[i] = removeNull[0];
                    } else {
                        data[i] = data[parseInt(i - 1)];
                    }
                }
                const value = data[i] && Number(data[i]) > 0 ? Number(data[i]) : (i > 0 ? newData[parseInt(i - 1)].value : 0);
                newData.push({ time: i, value });
            }
            /* for (var i = 0; i < data.length; i++) {
                const value = data[i] && Number(data[i]) > 0 ? Number(data[i]) : (i > 0 ? newData[parseInt(i - 1)].value : 0);
                newData.push({ time: i, value });
                //newData[i] = { time: ltime, value: data[i] && Number(data[i]) > 0 ? Number(data[i]) : i > 0 ? data[parseInt(i - 1)] : 0 };
                //if (i == (newData.length - 1)) change = newData[i] - newData[0];
            } */
            var change = newData[parseInt(newData.length - 1)].value - newData[0].value;
            //console.log("newData", newData, change, "chart24hr" + chartid); //return;
            try {
                if (document.getElementById(("chart24hr" + chartid))) {
                    document.getElementById(("chart24hr" + chartid)).innerHTML = "";
                }
            } catch (err) {
                //console.log(err);
            }
            const LightweightCharts = window.LightweightCharts;
            var container = document.getElementById(("chart24hr" + chartid));
            //console.log("container", container);
            if (container) {

                var chart = LightweightCharts.createChart(container, {
                    //theme: themeMode.get() == "dark" ? "Dark" : "Light",
                    handleScroll: {
                        mouseWheel: false,
                        pressedMouseMove: false,
                        horzTouchDrag: false,
                        vertTouchDrag: false
                    },
                    handleScale: {
                        axisPressedMouseMove: false,
                        mouseWheel: false,
                        pinch: false,
                    },
                    rightPriceScale: {
                        visible: false,
                        scaleMargins: {
                            top: 0,
                            bottom: 0,
                        },
                    },
                    crosshair: {
                        vertLine: {
                            visible: false,
                        },
                        horzLine: {
                            visible: false,
                        }
                    },
                    layout: {
                        backgroundColor: "#0000", //theme.palette.mode == "dark" ? "#252525" : "rgb(255, 255, 254)",
                        //textColor: "rgb(69, 181, 215, 1)",//theme.palette.mode == "dark" ? "#fff" : "#000"
                    },
                    timeScale: {
                        visible: false
                    },
                    grid: {
                        vertLines: {
                            visible: false
                        },
                        horzLines: {
                            visible: false
                        }
                    }
                });

                const lineSeries = chart.addAreaSeries({
                    topColor: (Number(change) < 0) ? "rgba(255, 99, 71, 0.56)" : "rgba(60, 179, 113, 0.56)",
                    bottomColor: (Number(change) < 0) ? "rgba(255, 99, 71, 0.04)" : "rgba(60, 179, 113, 0.04)",
                    lineColor: (Number(change) < 0) ? "rgba(255, 99, 71, 1)" : "rgba(60, 179, 113, 1)",
                    //color: (Number(change) < 0) ? "red" : "green",
                    priceLineVisible: false,
                    crosshairMarkerVisible: false
                });

                //if (!chartDrawn.get()) {

                lineSeries.setData(newData);

                chart.timeScale().fitContent();

                /*new ResizeObserver(entries => {
                    if (entries.length === 0 || entries[0].target !== container) { return; }
                    const newRect = entries[0].contentRect;
                    chart.applyOptions({ height: newRect.height, width: newRect.width });
                }).observe(container);*/

            } else {
                //console.log("chart div not found");
                //updateChart();
            }

        } catch (err) {
    setTimeout(() => {
        drawLineCharts();
    }, 100);
            //console.log("came to err", err);
        }
    }


    return (
        <Box id={"chart24hr" + chartid} sx={{ width: "100%", height: "42px" }} />
    )
}