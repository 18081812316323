
import {
    Box, IconButton, Paper, Tooltip, Typography,
} from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import Image from 'next/legacy/image';
//import axios from 'axios';
import useSWR from 'swr';
import RenderPrice from './RenderPrice';
import { myLoader, stringShort, numShort, favs } from '@/libs/fncs';
import { Launch } from '@mui/icons-material';
import RenderLogo from './RenderLogo';
import { useHookstate } from '@hookstate/core';
import { networkList } from '@/libs/store';
import { Favorite } from '@mui/icons-material';
import Clink from './Clink';
import AddressRender from './AddressRender';
import { Close } from '@mui/icons-material';

export default function PairTable({ iSearch, searchData, theme, router, favList, alerting }) {
    //const url = iSearch ? "https://data.cryptogems.info/search" : "https://data.cryptogems.info/fav";
    const data = useHookstate(searchData ? searchData : []);
    var localwallet = {};
    if (!iSearch) {
        localwallet = JSON.parse(localStorage.getItem("cgwallet"));
        localwallet["favs"] = Object.values(JSON.parse(localStorage.getItem("cgfav")));
        //console.log("localwallet pairtable", localwallet);
    }
    const fetcher = url => fetch(url, localwallet).then(async (res) => {
        res = { data: await res.json() };
        //var temp = pageName.get() == "dex" || pageName.get() == "token" ? res.data.data[0].result : res.data.data;
        var temp = res.data && res.data.data ? res.data.data : [];
        /* await Promise.allSettled(temp.map(async (row, i) => {
            temp[i].id = i + 1;
        })) */
        //console.log("temp", temp);
        data.set(temp);
        return res.data
    });

    useSWR(iSearch ? null : localwallet["favs"].length < 1 ? null : `https://data.cryptogems.info/favourites?address=${localwallet.address}&count=${localwallet["favs"].length}`, fetcher, { refreshInterval: 60000 }); // `https://data.cryptogems.info/search?chain=${netSelected.get()}&searchdata=${searchData.get()}`

    return (
        <Box sx={{ width: "100%", height: "100%", p: 0.5, overflow: "auto" }}>
            {data.get().map((row, i) => {
                const chainImage = row && row.chain ? networkList[row.chain].image : "/logo.svg";
                const image = row && row.t0 ? row.t0.image : null;
                const image1 = row && row.t1 ? row.t1.image : null;
                const symbol = row && row.t0 ? row.t0.symbol : "NA";
                const symbol1 = row && row.t1 ? row.t1.symbol : "NA";
                const usdValue = row && row.usdPrice ? row.usdPrice : 0;
                const volume = row && row.volume ? row.volume : 0;

                return (
                    <Paper key={i} sx={{ width: "100%", height: "35px", display: "flex", justifyContent: "space-between", alignItems: "center", mb: 0.5, p: 1 }}>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", overflow: "hidden" }}>
                            <Clink router={router} pageValue={"pair"} chainValue={row.chain} addValue={row.address} href={`/${row.chain}/pair/${row.address}`}>
                                <Tooltip title={row.address} placement="top" sx={{ overflow: "auto" }}>
                                    <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
                                        <Box sx={{ mr: 0.5, display: "flex", alignItems: "center", position: 'relative', width: "16px", height: "16px" }}>
                                            <Image src={chainImage} alt="Logo" layout="fill" objectFit={'contain'} quality={70} priority={true} />
                                        </Box>
                                        <Box sx={{ mr: 0.5, display: "flex", alignItems: "center" }}>
                                            <ArrowRightAlt sx={{ fontSize: 12 }} />
                                        </Box>
                                        <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                                            <Box sx={{ display: "flex", alignItems: "center", position: 'relative', width: "16px", height: "16px" }}>
                                                <RenderLogo image={image} value={symbol} width={"16px"} height={"16px"} />
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center", pl: 0.5, position: 'relative', width: "16px", height: "16px" }}>
                                                <RenderLogo image={image1} value={symbol1} width={"16px"} height={"16px"} />
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center", pl: 0.5 }}>
                                            <Typography sx={{ whiteSpace: "nowrap", fontSize: 12 }}>{stringShort(symbol, 0, 5)}</Typography>
                                            <Typography sx={{ whiteSpace: "nowrap", fontSize: 12, pl: 0.5, pr: 0.5 }}>/</Typography>
                                            <Typography sx={{ whiteSpace: "nowrap", fontSize: 12 }}>{stringShort(symbol1, 0, 5)}</Typography>
                                        </Box>
                                        {iSearch ? <Typography sx={{ display: "flex", alignItems: "center", fontSize: 12, pl: 0.5 }}>
                                            {stringShort(row.address, 1, 10)}
                                        </Typography> : <></>}
                                        {/* <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                                    <RenderPrice value={usdValue} usdtype={true} pdecimal={6} pfontsize={14} theme={theme} />
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                                    <AddressRender />
                                </Box> */}
                                    </Box>
                                </Tooltip>
                            </Clink>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", ml: 1, width: "76px" }}>
                            <RenderPrice value={usdValue} usdtype={true} pdecimal={6} pfontsize={12} theme={theme} tipDisabled={true} />
                        </Box>
                        {iSearch ?
                            <IconButton size='small' sx={{ ml: 1, color: favList.get()[row.address] ? 'red' : 'inherit' }} onClick={() => {
                                favs({ address: row.address, path: row.chain }, favList, alerting, true);
                            }}><Favorite sx={{ fontSize: 16 }} /></IconButton>
                            :
                            <IconButton size='small' sx={{ ml: 1 }} onClick={() => {
                                favs({ address: row.address, path: row.chain }, favList, alerting);
                            }}><Close sx={{ fontSize: 16 }} /></IconButton>}
                    </Paper>
                )
            })}
        </Box>
    )
}
