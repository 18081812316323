
import {
    Box, Tooltip, Typography
} from '@mui/material';
import { format } from 'timeago.js';

function RenderDate({ value }) {

    return (
        <Box sx={{ width: "100%", height: "100%", display: 'flex', alignItems: "center" }}>
            <Tooltip title={new Date(Math.floor(value * 1000)).toLocaleString('en-US', { hour12: true })} placement="top">
                <Typography sx={{ fontSize: 14 }}>{format(value * 1000)}</Typography>
            </Tooltip>
        </Box>
    )
}

export default RenderDate;