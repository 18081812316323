import { themed } from '@/libs/store';
import { useHookstate } from '@hookstate/core';
import {
    Box,
    Paper, Typography
} from '@mui/material';
import Image from 'next/legacy/image';

const partnerList = [
    {
        name: "TradingView",
        logow: "/logo/tradingview_white.svg",
        logob: "/logo/tradingview_black.svg",
        about: <Box>
            Our Technical Charts are provided by TradingView. A comprehensive trading and investment platform, offering an exceptional charting solution. It empowers traders and investors with cutting-edge analytical tools, including <a href={"https://www.tradingview.com/markets/cryptocurrencies/global-charts/"} aria-label="crypto market cap" target="_blank" rel="noopener noreferrer">crypto market cap</a>  charts and real-time market data. With its assistance, users can conveniently track the price of  <a href={"https://www.tradingview.com/symbols/BTCUSD/"} aria-label="BTC/USD" target="_blank" rel="noopener noreferrer"> BTC/USD</a>  price and stay informed about other relevant market trends.
        </Box>
    }
]

export default function Partners() {

    const themeMode = useHookstate(themed);

    return (
        <Paper sx={{ height: "100%", width: "100%", p: 1, overflow: "auto" }}>
            <Typography variant='h5'>CryptoGems Partners</Typography>
            {
                partnerList.map((row, i) => {

                    return (
                        <Paper key={i} elevation={4} sx={{ mt: 2, ml: 1, mr: 1, p: 1 }}>
                            <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                                <Box sx={{ width: "200px", height: "100px", p: 1, mr: 1 }}>
                                    <Box sx={{ width: "100%", height: "100%", position: "relative", overflow: "hidden" }}>
                                        <Image src={themeMode.get() == "light" ? row.logob : row.logow} alt={row.name} layout="fill" objectFit={'contain'} quality={100} priority={false} />
                                    </Box>
                                </Box>
                                <Box sx={{ width: `calc(100% - 200px)`, display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h6'>{row.name}</Typography>
                                    {
                                        row.about
                                    }
                                </Box>
                            </Box>
                        </Paper>
                    )
                })
            }
        </Paper>
    )

}