//import { useHookstate } from '@hookstate/core';
//import Router, { useRouter } from 'next/router';
import { getseo } from '@/libs/fncs';
import { useHookstate } from '@hookstate/core';
import { addressd, chaind, globaldata, historyDatad, isApp, marketd, mountedd, pageNod, paged, seod, sideaccord, sidedatad, tableLoad, tvwd } from '@/libs/store';

function Clink({ href, ext, children, pageValue, addValue, chainValue, router }) {

    //const router = useRouter()

    const tableLoading = useHookstate(tableLoad);
    const chainName = useHookstate(chaind);
    const pageName = useHookstate(paged);
    const seo = useHookstate(seod);
    const pageNo = useHookstate(pageNod);
    const address = useHookstate(addressd);
    const selAcc = useHookstate(sideaccord);
    const data = useHookstate(globaldata);
    const sideData = useHookstate(sidedatad);
    const historyData = useHookstate(historyDatad);
    const market = useHookstate(marketd);
    //const tvw = useHookstate(tvwd);

    function customLinkOnClick(e, href) {
        e.preventDefault();
        if (router.asPath != href) {
            if (isApp) {
                if (!tableLoading.get()) tableLoading.set(true);
                //data.set([]);
                //historyData.set([]);
                const hrefsplit = href.split('/').length;

                if (pageValue && pageName.get() != pageValue) {
                    pageName.set(pageValue);
                }
                if (chainValue && chainName.get() != chainValue) chainName.set(chainValue);
                if (pageNo.get() > 0) pageNo.set(0);
                if (addValue && address.get() != addValue) {
                    market.updated.set(false);
                    address.set(addValue);
                    if (selAcc.get() == 3) {

                    } else {
                        selAcc.set(1);
                    }
                } else {
                    if (selAcc.get() == 3) {

                    } else {
                        selAcc.set(2);
                    }
                }
                const seoData = pageName.get() == "pair" || pageName.get() == "dex" || pageName.get() == "token" ? getseo(chainName.get({ noproxy: true }), pageName.get({ noproxy: true }), address.get({ noproxy: true })) : getseo(chainName.get({ noproxy: true }), pageName.get({ noproxy: true }))

                seo[seoData.pagetype].merge({ tabTitle: seoData.titleData, description: seoData.descriptionData });

                //console.log("link", hrefsplit);
                router.push(hrefsplit == 4 ? `/[chain]/[pagetype]/[address]` : hrefsplit == 3 ? `/[chain]/[pagetype]` : `/[chain]`, href, { shallow: true });
                //router.push(href);//, undefined, { shallow: true });//, null, { shallow: true }
            } else {
                window.location = href;
            }

        }
    }

    if (ext) {
        return (
            <a href={href} style={{ width: "100%", height: "100%", color: 'inherit', display: "flex", alignItems: "center", textDecoration: "none" }} aria-label="extlink" target="_blank" rel="noopener noreferrer">
                {children}
            </a>
        )
    } else {
        return (
            <a href={href} onClick={(e) => { customLinkOnClick(e, href) }} style={{ width: "100%", height: "100%", color: 'inherit', display: "flex", alignItems: "center", textDecoration: "none" }} rel="noopener noreferrer">
                {children}
            </a>
        )
    }
}

export default Clink;